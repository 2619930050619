import React, {useEffect} from 'react';

import OurServices from './OurServices';
import Recruiting from './Recruiting';
import ExecutiveSearch from './ExecutiveSearch';
import MBA from './MBA';
import Training from './Training';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

function ServicePage(props) {
    useEffect(()=>{
        window.scrollTo(0, 0);
    })
    return(
        <div style={{width: "100vw"}}>
            <Header isWhite={true} isEn={props.isEn} setIsEn={props.setIsEn}/>
            <OurServices isEn={props.isEn}/>
            <Recruiting isEn={props.isEn}/>
            <ExecutiveSearch isEn={props.isEn}/>
            <MBA isEn={props.isEn}/>
            <Training isEn={props.isEn}/>
            <Footer isEn={props.isEn}/>
        </div>
    );
}

export default ServicePage;
// import React from 'react';

// import Footer from '../../components/Footer';

// function AboutUs() {
//     return(
//         <>
//             <Footer/>
//         </>
//     );
// }

// export default AboutUs;
import React, {useState} from 'react';
import {StyleSheet, css} from 'aphrodite';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import {contactUs} from '../util/email';

import common, { primaryColor } from '../common.css.js';

function ContactForm(props) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [email, setEmail] = useState("");
    const [content, setContent] = useState("");
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);
    const [success, setSuccess] = useState(false);
    function handleSubmit() {
        if(isValidInput()) {
            contactUs(firstName, lastName, email, content);
            setSuccess(true);
            setFirstName("");
            setlastName("");
            setEmail("");
            setContent("");
        } else {
            setShowError(true);
        }
    }
    
    function isValidEmail() {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    function isValidInput() {
        if (firstName == "" || lastName == "" || email == "" || content =="") {
            setError("Required fields cannot be empty.");
            return false;
        } else if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            setError("Please enter a valid email address.");
            return false;
        } else {
            setError("");
            setShowError(false);
            return true;
        }
    }

    let container = props.isWhite ? common.sectionContainer : common.coloredSectionContainer
    return(
        <div className={css(container)}>
            <h1 className={css(common.title)}>{props.title}</h1>
            
                {props.description &&
                <div className={css(common.centerFlexContainer)}>
                    <p className={css(styles.description)}>{props.description}</p>
                </div>
                }
            <form className={css(styles.formContainer)} noValidate autoComplete="off">
                <div className={css(styles.firstLineContainer)}>
                    <TextField
                        className={css(styles.shortForm)}
                        required
                        id="standard-basic" 
                        label={props.isEn?"First Name":"名"}
                        variant="outlined"
                        value={firstName}
                        onChange={(e)=>setFirstName(e.target.value)}
                        error={showError && firstName === ""}
                        helperText={showError && firstName === "" ? error : null}
                    />
                    <TextField
                        className={css(styles.shortForm)}
                        id="standard-basic" 
                        label={props.isEn?"Last Name":"姓"}
                        variant="outlined"
                        value={lastName}
                        onChange={(e)=>setlastName(e.target.value)}
                        required
                        error={showError && lastName === ""}
                        helperText={showError && lastName === "" ? error : null}
                    />
                    <TextField
                        className={css(styles.shortForm)}
                        id="standard-basic" 
                        label={props.isEn?"Email":"邮件"}
                        variant="outlined"
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                        required
                        error={showError && !isValidEmail()}
                        helperText={showError && !isValidEmail() ? "Please Enter a Valid Email" : null}
                    />
                </div>
                <TextField
                    className={css(styles.longForm)}
                    required
                    id="standard-basic" 
                    label={props.isEn?"How Can We Help?":"留言"}
                    variant="outlined"
                    multiline
                    rows="8"
                    value={content}
                    onChange={(e)=>setContent(e.target.value)}
                    error={showError && content === ""}
                    helperText={showError && content === "" ? error : null}
                />
            </form>
            {success && 
            <div className={css(styles.reminder)}>
                <p>
                {props.isEn ? "Your message is sent successfully." : 
                "您的留言已成功发送"}
                </p>
                <Button 
                    className={css(styles.button)}
                    onClick={()=>setSuccess(false)}
                >
                    <CloseIcon/>
                </Button>
            </div>}
            <div className={css(common.centerFlexContainer)}>
                <Button 
                    className={css(common.button)} 
                    variant="contained"
                    onClick={handleSubmit}
                >
                    {props.isEn?"Submit":"提交"}
                </Button>
            </div>
        </div>
    );
}

export default ContactForm;

const styles = StyleSheet.create({
    formContainer: {
        
    },
    firstLineContainer: {
        display: "flex",
        justifyContent: 'space-between',
        marginBottom: 48,
        '@media (max-width: 599px)': {
            flexDirection: "column",
            marginBottom: 0
        }
    },
    shortForm: {
        width: "30%",
        '@media (max-width: 599px)': {
            width: "100%",
            marginBottom: 48
        }
    },
    longForm: {
        width: "100%",
        marginBottom: 48,
        
    },
    description: {
        // textAlign: "center",
        width: "70%",
        textAlign: "left",
        marginTop: 24,
        marginBottom: 48
    },
    reminder: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: primaryColor,
        color: "white",
        height: 60,
        width: 360,
        marginBottom: 36,
        position: "relative"
    },
    button: {
        position:"absolute",
        top: 0,
        right: -6,
        color: "white"
    }
})
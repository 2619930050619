import React, {useEffect, useState} from 'react';

import Corporates from './Corporates';
import Universities from './Universities';
import PartnerIntro from './PartnerIntro';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
// import SocialBar from '../../components/SocialBar';
import ContactForm from '../../components/ContactForm';

function AboutUs(props) {
    const headerHeight = 96;
    const [introRef, setIntroRef] = useState(null);
    const [cRef, setCRef] = useState(null);
    const [uRef, setURef] = useState(null);
    const [pRef, setPRef] = useState(null);
    const [toCHeight, setToC] = useState(0);
    const [toUHeight, setToU] = useState(0);
    const [scrollHeight, setScroll] = useState(0);
    useEffect(()=>{
        window.scrollTo(0, 0);
        if(introRef && cRef && uRef) {
            const timer = setTimeout(() => {
                console.log('This will run after 1 second!')
                console.log(introRef.offsetTop, cRef.offsetTop, uRef.offsetTop);
                setToC(cRef.offsetTop - headerHeight);
                setToU(uRef.offsetTop - headerHeight);
                setScroll(pRef.offsetTop - headerHeight);
            }, 1000);
            return () => clearTimeout(timer);
        }
    },[introRef, cRef, uRef]);
    const text = props.isEn 
    ? "Interested in partnering with us?  Please contact elena@internationalrecruit.net or fill out the contact form, we will reach out to you as soon as possible." 
    : "如有兴趣与我们合作，请联系elena@internationalrecruit.net或填写如下联系表格，我们将尽快与您联系。"
    return(
        <div style={{width: "100vw", position: "relative"}}>
            <Header 
                isWhite={true} 
                isEn={props.isEn} 
                setIsEn={props.setIsEn}
            />
            <div ref={(elem) => setIntroRef(elem)}>
                <PartnerIntro 
                    isEn={props.isEn}
                    toCHeight={toCHeight}
                    toUHeight={toUHeight}
                />
            </div>

            <div ref={(elem) => setCRef(elem)}>
                <Corporates 
                    isEn={props.isEn}
                    ref={(elem) => setCRef(elem)}
                    scrollHeight={scrollHeight}
                />
            </div>

            <div ref={(elem) => setURef(elem)}>
                <Universities 
                    isEn={props.isEn}
                    ref={(elem) => setURef(elem)}
                    scrollHeight={scrollHeight}
                />
            </div>

            <div ref={(elem) => setPRef(elem)}>
                <ContactForm
                    title = {props.isEn ? "Partner With Us" : "与我们合作"}
                    description = {text}
                    isWhite={true}
                    isEn={props.isEn}
                />
            </div>

            <Footer isEn={props.isEn}/>
        </div>
    );
}

export default AboutUs;
import {StyleSheet} from 'aphrodite';

export const primaryColor = "#317EC4";
export const darkTextColor = "#000000";
export const lightTextColor = "#FFFFFF";

export default StyleSheet.create({
    "title": {
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: 48,
        marginTop: 0,
        paddingTop: 24,
        ":after": {
            content: '""', /* This is necessary for the pseudo element to work. */ 
            display: "block", /* This will put the pseudo element on its own line. */
            margin: "0 auto", /* This will center the border. */
            width: "10%", /* Change this to whatever width you want. */
            paddingTop: 10, /* This creates some space between the element and the border. */
            borderBottom: "3px solid #317EC4" /* This creates the border. Replace black with whatever color you want. */
        },
        '@media (max-width: 599px)': {
            paddingTop: 12,
            marginBottom: 12
        }
    },
    "sectionContainer": {
        padding: "48px 10%"
    },
    "coloredSectionContainer": {
        backgroundColor: "#F0F8FF",
        padding: "48px 10%"
    },
    "button": {
        backgroundColor: primaryColor,
        color: lightTextColor,
        fontWeight: "bold"
    },
    "centerFlexContainer": {
        display: "flex",
        justifyContent: "center"
    },
    "verticalCenterFlexContainer": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    "flexContainer": {
        display: "flex",
    },
    "link": {
        textDecoration: "none"
    },
    "mobileContainer": {
        width: "100vw"
    }
});
import React from 'react';
import {StyleSheet, css} from 'aphrodite';

import common from '../../common.css.js';
import MBAImage from '../../images/MBA.jpg';
function MBA(props) {
    const texts = props.isEn ? english : chinese;
    const title = props.isEn ? "Global MBA Programs" : "国际MBA项目";
    return(
        <div className={css(common.sectionContainer)}>
            <h1 className={css(common.title)}>{title}</h1>
            <div className={css(styles.contentContainer)}>
            <div className={css(common.verticalCenterFlexContainer)}>
                {/* <p>{text}</p> */}
                <ul>
                    {texts.map(text=>{
                        return <li className={css(styles.text)}>{text}</li>
                    })}
                </ul>
            </div>
            <img 
                src={MBAImage} 
                alt="universities image" 
                className={css(styles.image)}
            />
            </div>
        </div>
    );
}

export default MBA;

const styles = StyleSheet.create({
    image: {
        // width: "45%",
        // height: "auto",
        width: "45%",
        height: "100%",
        marginLeft: "5%",
        '@media (max-width: 599px)': {
            margin: 0,
            width: "100%",
        }
    },
    contentContainer: {
        display: "flex",
        justifyContent: "space-between",
        '@media (max-width: 599px)': {
            flexDirection: "column"
        }
    },
    text: {
        marginBottom: 22
    },
})

const english=[
    `We generate CEO searches among world’s top 50 business schools, offering the best executive search solutions that complement and strengthen your leadership strategies.`,
    `Years of experience in initiating Global MBA Programs with multi-national companies and top business schools in North America, Asia, Europe and Australia`,
    `Branches in North America and Asia`
];

const chinese=[
    `我们在全球排名前50的商学院中进行CEO搜索、筛选、和招聘，并向企业提供最佳的解决方案，以补充并加强您的团队策略。`,
    `多年来与北美、亚洲、欧洲、和澳大利亚的跨国公司还有顶尖商学院共同发起全球跨境MBA项目`,
    `在北美和亚洲设有办公室，更好的服务跨境业务需求`
];
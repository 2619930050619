import React from 'react';
import {StyleSheet, css} from 'aphrodite';

import common from '../../common.css.js';
function JobSearch(props) {
    const title = props.isEn ? "Become A Talent" : "加入我们的人才库";
    const texts = props.isEn ? english : chinese;
    return(
        <div className={css(common.coloredSectionContainer)}>
            <h1 className={css(common.title)}>{title}</h1>
            <div className={css(styles.textContainer)}>
                    {texts.map(text=>{
                        return <p className={css(styles.text)}>{text}</p>
                    })}
            </div>
        </div>
    );
}

export default JobSearch;

const styles = StyleSheet.create({
    image: {
        width: "45%",
        height: "auto",
        marginLeft: "5%"
    },
    text: {
        marginBottom: 22,
        textAlign: "center",
        width: "70%"
    },
    textContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    contentContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
})

const english=[
    `We help candidates with middle to senior levels who are looking for new opportunities or are planning to explore local or oversea professional experience to land in their jobs.`,
    `Submit your resume or LinkedIn URL, our consultant will contact you very soon.`
]

const chinese=[
    `我们为中高级正在寻找或计划寻找当地或海外机会的求职者识别并搜寻工作机会。`,
    `如果您在考虑新的工作机会，请提交您的简历或LinkedIn URL，我们的顾问将尽快与您联系。`
]
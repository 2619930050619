import React from 'react';
import {StyleSheet, css} from 'aphrodite';

import common from '../../common.css.js';
import whoWeAre from '../../images/whoWeAre.jpg';
function OurAdvantages(props) {
    const texts = props.isEn ? english : chinese;
    return(
        <div className={css(common.coloredSectionContainer)}>
        <h1 className={css(common.title)}>{props.isEn ? "Who We Are" : "关于我们"}</h1>
            <div className={css(styles.contentContainer)}>
                <div>
                    {texts.map(text=>{
                        return <p>{text} <br/></p>
                    })}
                </div>
                <img src={whoWeAre} alt="our image" className={css(styles.image)}/>
            </div>
        </div>
    );
}

export default OurAdvantages;

const styles = StyleSheet.create({
    contentContainer: {
        display: "flex",
        justifyContent: "space-between",
        '@media (max-width: 599px)': {
            flexDirection: "column"
        }
    },
    image: {
        // width: "45%",
        // height: "auto",
        width: "45%",
        height: "100%",
        marginLeft: "5%",
        '@media (max-width: 599px)': {
            marginLeft: 0,
            width: "100%",
        }
    },
})

const english=[
    `International Recruiting LLC is the wholly owned subsidiary of Shanghai Yuanzhi Human Resources Management Co., Ltd. We offering human resource services focused on Healthcare, education, and IT industries.`,
    `We are experts at identifying professional management and executive level candidates that have the educational and professional backgrounds required to fill your specific needs.`,
    `We also provide training and coaching service to support fast growing talents’ needs in High Tech industry. It is our mission to search, identify, recruit, and develop your next superstar.`
]

const chinese=[
    `International Recruiting LLC是上海远志人力资源管理有限公司的全资子公司。我们提供的服务专注于医疗保健，教育和IT等行业。`,
    `帮助您识别满足您特定需求的专业背景高度契合的高管候选人并为领导层提供持续的高管教练服务。`,
    `我们的使命是搜索，识别和招聘下一位职场超级巨星。`
]
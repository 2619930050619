import React from 'react';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TranslateIcon from '@material-ui/icons/Language';
import {Link} from 'react-router-dom';
import {css, StyleSheet} from 'aphrodite';

import common, {primaryColor} from '../common.css.js';

export default function RightDrawer(props) {
    let list = props.isEn ? englishList : chineseList
    let translateButtonStyle = props.isWhite ? css(styles.blackTranslateButton) : css(styles.whiteTranslateButton);
    let english = props.isEn ? css(styles.selectedLanguage) : css(styles.unselectedLanguage);
    let chinese = props.isEn ? css(styles.unselectedLanguage) : css(styles.selectedLanguage);
    
    return (
      <div className={css(styles.container)}>
        <Drawer 
            anchor="right" 
            open={props.open} 
            onClose={props.toggleOpen}
        >
          <List>
            {list.map((item) => (
                <Link to={item[0]} className={css(common.link, styles.link)}>
                    <ListItem button key={item[0]}>
                        <ListItemText primary={item[1]} />
                    </ListItem>
                </Link>
            ))}
            <Divider/>
            <Button 
                className={translateButtonStyle}
                onClick={props.setIsEn}
            >
                <span className={english}>EN/ </span>
                <span className={chinese}>中</span>
                <TranslateIcon className={css(styles.translateIcon)}/>
            </Button>
          </List>
        </Drawer>
      </div>
    );
  }

  const englishList=[
      ["/about","About Us"], 
      ["/service","Our Services"], 
      ["/partner", "Partner With Us"], 
      ["/join", "Become A Talent"], 
      ["/contact", "Contact"],
  ];
  const chineseList=[
      ["/about","关于我们"], 
      ["/service","服务概览"], 
      ["/partner", "与我们合作"], 
      ["/join", "加入人才库"], 
      ["/contact", "联系我们"],
    ];

  const styles = StyleSheet.create({
    container: {
        
    },
    whiteTranslateButton: {
        color: "white",
        cursor: "pointer",
        ":hover": {
            color: primaryColor,
            background: "none"
        },
        marginLeft: 8
    },
    blackTranslateButton: {
        cursor: "pointer",
        ":hover": {
            color: primaryColor,
            background: "none"
        },
        marginLeft: 24
    },
    selectedLanguage: {
        color: primaryColor
    },
    unselectedLanguage: {
        color: "black"
    },
    translateIcon: {
        fontSize: 14,
        color: "black"
    },
    link: {
        color: "black"
    }
  })
import React, {useEffect} from 'react';

import WhoWeAre from './WhoWeAre';
import OurAdvantages from './OurAdvantages';
import OurHistory from './OurHistory';
import PartnerWithUs from './PartnerWithUs';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
// import SocialBar from '../../components/SocialBar';

function AboutUs(props) {
    useEffect(()=>{
        window.scrollTo(0, 0);
    })
    return(
        <div style={{width: "100vw"}}>
            <Header isWhite={true} isEn={props.isEn} setIsEn={props.setIsEn}/>
            <WhoWeAre isEn={props.isEn}/>
            <OurHistory isEn={props.isEn}/>
            <OurAdvantages isEn={props.isEn}/>
            <PartnerWithUs isEn={props.isEn}/>
            <Footer isEn={props.isEn}/>
        </div>
    );
}

export default AboutUs;
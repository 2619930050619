import React, {useState, useEffect} from 'react';
import {StyleSheet, css} from 'aphrodite';

import common, {primaryColor} from '../../common.css.js';
import corporates from '../../images/corporates.jpg';
import stepIcon from '../../images/stepperIcon.svg';
import icon1 from '../../images/1.svg';
import icon2 from '../../images/2.svg';
import icon3 from '../../images/3.svg';
import icon4 from '../../images/4.svg';
import icon5 from '../../images/5.svg';
import { Button } from '@material-ui/core';

function Corporates(props) {
    const [height, setHeight] = useState(props.scrollHeight);
    useEffect(()=>{
        if(props.scrollHeight != height) {
            setHeight(props.scrollHeight);
        }
    }, [props.scrollHeight]);

    function handleScroll() {
        console.log(height);
        window.scrollTo(0, height);
    }

    const title = props.isEn ? "For Corporates" : "与企业合作";
    const subTitle = props.isEn ? "Process" : "我们期望与我们的客户建立长期的战略合作伙伴关系，执行高效的合作流程并达成有效的结果。";
    const texts = props.isEn ? english : chinese;
    return(
        <div className={css(common.sectionContainer)}>
            <h1 className={css(common.title)}>{title}</h1>
            <p className={css(styles.subTitle)}>{subTitle}</p>
            <div className={css(styles.contentContainer)}>
                <div className={css(styles.stepContainer)}>
                    {texts.map((text, index)=>{
                        let style = index != 4 ? css(styles.stepText) : css(styles.stepTextLast);
                        return(
                            <div>
                                <img src={icons[index]} alt="step icon" className={css(styles.stepIcon)}/>
                                <p className={style}>
                                    {text}
                                </p>
                            </div>
                        );
                    })}
                </div>
                <img 
                    src={corporates} 
                    alt="universities image" 
                    className={css(styles.image)}
                />
            </div>
            <div className={css(common.centerFlexContainer)}>
                <Button 
                    variant="contained"
                    className={css(common.button, styles.button)}
                    onClick={handleScroll}
                >
                    {props.isEn ? "Partner With Us" : "与我们合作"}
                </Button>
            </div>
        </div>
    );
}

export default Corporates;

const styles = StyleSheet.create({
    image: {
        // width: "45%",
        // height: "auto",
        width: "45%",
        height: "100%",
        marginLeft: "5%",
        '@media (max-width: 599px)': {
            width: "100%",
            margin: 0
        }
    },
    button: {
        marginTop: 48
    },
    contentContainer: {
        display: "flex",
        justifyContent: "space-between",
        '@media (max-width: 599px)': {
            flexDirection: "column"
        }
    },
    stepContainer: {
        position: "relative"
    },
    stepText: {
        borderLeft: `2px solid ${primaryColor}`,
        paddingLeft: 24,
        paddingBottom: 48,
        margin: 0
    },
    stepTextLast: {
        paddingLeft: 24,
        paddingBottom: 24,
        margin: 0
    },
    stepIcon: {
        width: 24,
        height: "auto",
        marginLeft: -12,
        marginBottom: -4,
        position: "absolute",
    },
    subTitle: {
        fontWeight: "bold",
        fontSize: 20,
        marginLeft: -6,
        marginBottom: 48
    }
})

const english=[
    `We gain an in-depth understanding of an organization’s needs, expectations, and priorities in order to determine the desired attributes of an ideal candidate.`,
    `We apply our comprehensive understanding of our potential candidates’ professional backgrounds, experiences, competencies, and motivations to select and present the best-suited talents to our clients.`,
    `We communicate with clients on a weekly basis to update them with our searching process.`,
    `We provide ongoing leadership coaching services for executive level placements to aid their transition.`,
    `Through this process, we ensure that our client always gets the ideal candidate that is optimally suited to their needs.`
];

const chinese=[
    `深入了解客户的业务发展和人才需求，期望以及职位优先级，以确定理想候选人的搜寻方向和轮廓`,
    `全面了解评估潜在候选人的专业背景、经验、能力和动机，选择和推送匹配度最高的候选人给我们的客户`,
    `定期与我们的客户沟通交流，例如每周我们对我们的搜索进行汇总并更新给我们的客户`,
    `为高管提供持续的领导力辅导服务，以帮助他们实现转型`,
    `提供持续的专业服务，为中高层管理人员提供有关领导力的课程和咨询，以帮助他们管理能力的提升并在企业内部发挥更为有效的影响力`
]

const icons=[icon1, icon2, icon3, icon4, icon5];

import React from 'react';
import {StyleSheet, css} from 'aphrodite';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import Media from 'react-media';

import AboutUsImage from '../../images/aboutUs.jpg';
import common from '../../common.css.js';
import {primaryColor} from '../../common.css.js';
function AboutUs(props) {
    function renderButton() {
        return(
            <div className={css(styles.buttonContainer)}>
                <Link to="/about" style={{textDecoration:"none"}}>
                <Button 
                    variant="contained"
                    color={primaryColor}
                    className={css(common.button)}
                >
                    {props.isEn ? "Learn More" : "了解更多"}
                </Button>
                </Link>
            </div>
        );
    }

    return(
        <div className={css(common.sectionContainer)}>
            <h1 className={css(common.title)}>{props.isEn ? "About Us" : "关于我们"}</h1>
            <div className={css(styles.contentContainer)}>
                <div className={css(styles.textButtonContainer)}>
                    <p className={css(styles.text)}>{props.isEn ? text1English : text1Chinese}</p>
                    {/* <br/> */}
                    <p className={css(styles.text)}>{props.isEn ? text2English : text2Chinese}</p>
                    <Media query={{ minWidth: 600 }}>
                        {matches => matches && renderButton()}
                    </Media>
                </div>
                <img 
                    className={css(styles.image)}
                    src={AboutUsImage} 
                    alt='about us pic'
                />
                <Media query={{ maxWidth: 599 }}>
                        {matches => matches && 
                        <div className={css(common.centerFlexContainer)}>
                            {renderButton()}
                        </div>}
                </Media>
            </div>
        </div>
    );
}

export default AboutUs;

const styles = StyleSheet.create({
    contentContainer: {
        display: "flex",
        justifyContent: "space-between",
        '@media (max-width: 599px)': {
            flexDirection: "column"
        }
    },
    textButtonContainer: {
        width: "50%",
        '@media (max-width: 599px)': {
            width: "100%",
        }
    },
    text: {
        textAlign: "left",
        marginRight: 48,
        '@media (max-width: 599px)': {
            width: "100%",
            height: "auto",
            marginBottom: 24
        }
    },
    image: {
        // objectFit: "fill",
        width: "50%",
        height: "100%",
        '@media (max-width: 599px)': {
            width: "100%",
            height: "auto",
            marginTop: 24
        }
    },
    buttonContainer: {
        display: "flex",
        marginTop: 48
    },
})

const text1English = `International Recruiting is an international human resources service provider specialized in Healthcare, education, and IT industries. `;
const text2English = `We support you with our networks and expertise to identify and hand-pick highly specialized candidates to fit your unique needs for local/gobal sourcing & hiring and talents development.`;
const text1Chinese = `Internatonal Recruiting LLC是一家综合性人力资源管理服务公司， 我们专注和擅长医疗、教育 、互联网等领域。致力于成为被信赖的专业人力资源供应商和服务商。`;
const text2Chinese = `我们以丰富的关系网络和专业知识为企业提供支持，以筛选、识别、和推荐专业的精英候选人，来满足您独特的全球/当地人才需求和发展。`
import React, {useEffect} from 'react';
import {css,StyleSheet} from 'aphrodite';

import Header from '../../components/Header';
import JobSearch from './JobSearch';
import ResumeForm from './ResumeForm';
import Footer from '../../components/Footer';
// import SocialBar from '../../components/SocialBar';

function JoinPage(props) {
    useEffect(()=>{
        window.scrollTo(0, 0);
    });
    return(
        <div className={css(styles.container)}>
            <Header  isWhite={true} isEn={props.isEn} setIsEn={props.setIsEn}/>
            <JobSearch isEn={props.isEn}/>
            <ResumeForm isEn={props.isEn}/>
            <Footer isEn={props.isEn}/>
        </div>
    );
}

const styles=StyleSheet.create({
    container: {
        width: "100vw"
    }
})
export default JoinPage;
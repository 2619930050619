import React, {useEffect} from 'react';
import {StyleSheet, css} from 'aphrodite';

import common, {primaryColor} from '../../common.css.js';
import seattle from '../../images/seattle.png';
import shanghai from '../../images/shanghai.png';
import calgary from '../../images/calgary.jpg';

function ContactDetail(props) {
    useEffect(()=>{
        window.scrollTo(0, 0);
    });
    const title = props.isEn ? "Contact" : "联系我们";
    const shanghaiTexts = props.isEn ? englishShanghaiTexts : chineseShanghaiTexts;
    const seattleTexts = props.isEn ? englishSeattleTexts : chineseSeattleTexts;
    const calgaryTexts = props.isEn ? englishCalgaryTexts : chineseCalgaryTexts;
    return(
        <div className={css(styles.fullPageContainer)}>
        <div className={css(common.coloredSectionContainer)}>
            <h1 className={css(common.title)}>{title}</h1>
            <div className={css(styles.contentContainer)}>
                <img 
                    src={seattle} 
                    alt="seattle image" 
                    className={css(styles.image)}
                />
                <div className={css(styles.textContainer)}>
                    <p className={css(styles.office)}>{props.isEn ? "Seattle U.S. Office" : "西雅图美国办公室"}</p>
                    <div className={css(common.verticalCenterFlexContainer)}>
                            {seattleTexts.map(text=>{
                                return <p className={css(styles.text)}>{text}</p>
                            })}
                    </div>
                </div>
            </div>
            <div className={css(styles.contentContainer)}>
                <img 
                    src={shanghai} 
                    alt="shanghai image" 
                    className={css(styles.image)}
                />
                <div className={css(styles.textContainer)}>
                    <p className={css(styles.office)}>{props.isEn ? "Shanghai, China Office" : "中国上海办公室"}</p>
                    <div className={css(common.verticalCenterFlexContainer)}>
                            {shanghaiTexts.map(text=>{
                                return <p className={css(styles.text)}>{text}</p>
                            })}
                    </div>
                </div>
            </div>
            <div className={css(styles.contentContainer)}>
                <img 
                    src={calgary} 
                    alt="calgary image" 
                    className={css(styles.image)}
                />
                <div className={css(styles.textContainer)}>
                    <p className={css(styles.office)}>{props.isEn ? "Calgary, Canada Office" : "加拿大卡尔加里办公室"}</p>
                    <div className={css(common.verticalCenterFlexContainer)}>
                            {calgaryTexts.map(text=>{
                                return <p className={css(styles.text)}>{text}</p>
                            })}
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default ContactDetail;

const styles = StyleSheet.create({
    fullPageContainer: {
        minWidth: "100vw"
    },
    image: {
        // width: "45%",
        // height: "auto",
        width: "45%",
        height: "100%",
        marginRight: "5%",
        '@media (max-width: 599px)': {
            marginLeft: 0,
            width: "100%",
        }
    },
    text: {
        marginBottom: 22,
        '@media (max-width: 599px)': {
            textAlign: "center",
            marginBottom: 0
        }
    },
    textContainer: {
        width: "50%",
        '@media (max-width: 599px)': {
            width: "100%",
            marginBottom: 24
        }
    },
    contentContainer: {
        display: "flex",
        // justifyContent: "space-between",
        marginBottom: "5%",
        textAlign: "left",
        '@media (max-width: 599px)': {
            flexDirection: "column",
            textAlign: "center",
        }
    },
    office: {
        fontSize: 24,
        color: primaryColor,
        fontWeight: "bold"
    }
})

const englishSeattleTexts=[
    `500 108th Ave NE Suite 1100, Bellevue, WA 98004`,
    `Tel:1 (206) 850 4883`,
    `Email: elena@internationalrecruit.net`,
];

const englishShanghaiTexts=[
    `1405 Tongsheng Building, No.458 Fushan Road,Shanghai, China`,
    `Tel: 86 (21) 610 93196`,
    `Email: admin@yuanzhihr.com`,
];

const englishCalgaryTexts=[
    `Unit 1208, 734 7 Ave SW, Calgary, AB T2P 3P8`,
    `Tel:  1 (403) 383 0811`,
    `Email: zijiaohan@internationalrecruit.net`,
];

const chineseSeattleTexts=[
    `500 108th Ave NE Suite 1100, Bellevue, WA 98004`,
    `电话: 1 (206) 850 4883`,
    `邮件: elena@internationalrecruit.net`,
];

const chineseShanghaiTexts=[
    `上海浦东新区福山路458号同盛大厦1405室`,
    `电话: 86 (21) 610 93196`,
    `邮件: admin@yuanzhihr.com`,
];

const chineseCalgaryTexts=[
    `Unit 1208, 734 7 Ave SW, Calgary, AB T2P 3P8`,
    `电话: 1 (403) 383 0811`,
    `邮件: zijiaohan@internationalrecruit.net`,
];
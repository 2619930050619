import React from 'react';
import {StyleSheet, css} from 'aphrodite';
import Button from '@material-ui/core/Button';

import globe from '../../images/globe.svg';
import locate from '../../images/locate.svg';
import mba from '../../images/mba.svg';
import train from '../../images/train.svg';

import common, {primaryColor} from '../../common.css.js';
function Testimonials(props) {
    const text = props.isEn ? english : chinese
    return(
        <div className={css(common.sectionContainer)}>
            <h1 className={css(common.title)}>{props.isEn ? "Testimonials" : "诚信、专业、高效"}</h1>
            <p className={css(styles.upperQuote)}>“</p>
            <p className={css(styles.text)}>{text}</p>
            <p className={css(styles.lowerQuote)}>”</p>
        </div>
    );
}

export default Testimonials;

const styles = StyleSheet.create({
    quoteContainer: {
        display: "flex",
        justifyContent: "space-between"
    },
    upperQuote: {
        textAlign: "left",
        fontSize: 72,
        color: primaryColor,
        marginBottom: 0
    },
    lowerQuote: {
        textAlign: "right",
        fontSize: 72,
        color: primaryColor,
        marginTop: 24
    },
    text: {
        marginLeft: 48,
        marginRight: 24,
        marginTop: 0
    }
})

const english = `Our experience in the Healthcare, Education, and IT industries provides us the networks and expertise to identify and hand-pick highly specialized candidates to fit your unique needs for global sourcing and hiring.`;
const chinese = `远志二十余年来在医疗、教育和IT行业的经验为我们提供了丰富的人际网络和专业知识，以筛选、识别、和推荐专业的精英候选人，来满足您独特的当地/全球人才需求和发展。`;
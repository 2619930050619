import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {StyleSheet, css} from 'aphrodite';
import Button from '@material-ui/core/Button';
import TranslateIcon from "@material-ui/icons/Language";
import {connect} from 'react-redux';
import Media from 'react-media';
import MenuIcon from '@material-ui/icons/Menu';

import Drawer from './Drawer';
import blueLogo from '../images/logoBlue.svg';
import whiteLogo from '../images/logoWhite.svg'
import common, {lightTextColor, primaryColor} from '../common.css.js';
import { blue } from '@material-ui/core/colors';
import { toggleLanguage } from '../actions';



const mapStateToProps = (state, ownProps) => ({
    active: ownProps.language === state.value
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    onClick: () => dispatch(toggleLanguage(ownProps.language))
})
  


function Header(props) {
    // let language = store.getState();
//    const[isEn, setEn] = useState(true);
    // return <div>  </div>;
    let english = props.isEn ? css(styles.selectedLanguage) : css(styles.unselectedLanguage);
    let chinese = props.isEn ? css(styles.unselectedLanguage) : css(styles.selectedLanguage);
    let headerStyle = props.isWhite ? css(styles.whiteHeader) : css(styles.noColorHeader);
    let buttonStyle = props.isWhite ? css(styles.blackNavButton) : css(styles.whiteNavButton);
    let translateButtonStyle = props.isWhite ? css(styles.blackTranslateButton) : css(styles.whiteTranslateButton);
    let logo = props.isWhite ? blueLogo : whiteLogo;
    let mobileMenu = props.isWhite ? css(styles.mobileMenuButton) : css(styles.mobileMenuButtonWhite)

    const[drawerOpen, setDrawerOpen] = useState(false);
    function renderDesktopHeader() {
        return(
            <div className={headerStyle}>
                <Link to="/" className={css(styles.logoLink)}>
                    <img className={css(styles.logo)} src={logo} alt="logo"/>
                </Link>
                <div>
                    <div className={css(styles.firstRow)}>
                        <Button 
                            className={translateButtonStyle}
                            onClick={props.setIsEn}
                        >
                            <span className={english}>EN</span>
                            / 
                            <span className={chinese}>中</span>
                            <TranslateIcon className={css(styles.translateIcon)}/>
                        </Button>
                    </div>
                    <div className={css(styles.secondRow)}>
                        <div className={css(styles.navContainer)}>
                            <Link to="/about" className={css(styles.navLink)}>
                                <Button className={buttonStyle}>
                                    {props.isEn ? "About Us" : "关于我们"}
                                </Button>
                            </Link>
                            <Link to="/service" className={css(styles.navLink)}>
                                <Button className={buttonStyle}>
                                    {props.isEn ? "Our Services" : "服务概览"}
                                </Button>
                            </Link>
                            <Link to="/partner" className={css(styles.navLink)}>
                                <Button className={buttonStyle}>
                                    {props.isEn ? "Partner With Us" : "与我们合作"}
                                </Button>
                            </Link>
                            <Link to="/join" className={css(styles.navLink)}>
                                <Button className={buttonStyle}>
                                    {props.isEn ? "Becoming A Talent" : "加入人才库"}
                                </Button>
                            </Link>
                            <Link to="/contact" className={css(styles.navLink)}>
                                <Button className={buttonStyle}>
                                    {props.isEn ? "Contact" : "联系我们"}
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function renderMobileHeader() {
        return(
            <div className={headerStyle}>
                <div className={css(styles.mobileContainer)}>
                    <Link to="/" className={css(styles.logoLink)}>
                        <img className={css(styles.logo)} src={logo} alt="logo"/>
                    </Link>
                    <Button 
                        className={mobileMenu}
                        onClick={()=>setDrawerOpen(true)}
                    >
                        <MenuIcon/>
                    </Button>
                </div>
                <Drawer 
                    open={drawerOpen}
                    toggleOpen={()=>setDrawerOpen(!drawerOpen)}
                    isEn={props.isEn}
                    setIsEn={props.setIsEn}
                />
            </div>
        );
    }

    return(
        <Media query={{ maxWidth: 599 }}>
          {matches => matches ? renderMobileHeader() : renderDesktopHeader()}
        </Media>
    )
}

// export default Header;
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);

const styles = StyleSheet.create({
    whiteHeader: {
        backgroundColor: "white",
        position: "sticky",
        top: 0,
        zIndex: 10,
        height: 96,
        boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
        '@media (max-width: 599px)': {
                height: 72,
                top: -12,
        }
    },
    noColorHeader: {
        position: "absolute",
        // position: "sticky",
        // top: 240,
        top: 244,
        zIndex: 150,
        height: 108,
        minWidth: "100%",
        '@media (max-width: 599px)': {
            top: 144,
        }
    },
    firstRow: {
        display: "flex",
        justifyContent: "flex-end"
    },
    secondRow: {
        // display: "flex",
        // justifyContent: "space-between"
        paddingRight: 60
    },
    navContainer: {
        display: "flex",
        justifyContent: "flex-end",
        fontSize: "14px",
    },
    navLink: {
        textDecoration: "none"
    },
    blackNavButton: {
        cursor: "pointer",
        ":hover": {
            color: primaryColor,
            background: "none"
        }
    },
    whiteNavButton: {
        color: "white",
        cursor: "pointer",
        ":hover": {
            color: primaryColor,
            background: "none"
        }
    },
    logo: {
        '@media (min-width: 600px)': {
            marginLeft: 48,
            marginBottom: -48,
            width: "35%",
        },
        '@media (max-width: 599px)': {
            marginLeft: 24,
            marginTop: -24
        }
    },
    logoLink: {
        width: "20%",
        marginTop:0,
    },
    whiteTranslateButton: {
        color: "white",
        cursor: "pointer",
        ":hover": {
            color: primaryColor,
            background: "none"
        }
    },
    blackTranslateButton: {
        cursor: "pointer",
        ":hover": {
            color: primaryColor,
            background: "none"
        }
    },
    translateIcon: {
        fontSize: 14,
        paddingRight: 60
    },
    selectedLanguage: {
        color: primaryColor
    },
    unselectedLanguage: {

    },
    mobileContainer: {
        paddingTop: 34,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start"
    },
    mobileMenuButton: {
        marginTop: -11,
        color: primaryColor
    },
    mobileMenuButtonWhite: {
        marginTop: -11,
        color: "white"
    }
});
import React, {useEffect} from 'react';

import Header from '../../components/Header';
import Footer from "../../components/Footer";
import ContactDetail from './ContactDetail';
function ContactPage(props) {
    useEffect(()=>{
        window.scrollTo(0, 0);
    });
    return(
        <div style={{width: "100vw"}}>
        <Header isWhite={true} isEn={props.isEn} setIsEn={props.setIsEn}/>
        <ContactDetail isEn={props.isEn}/>
        <Footer isEn={props.isEn}/>
        </div>
    );
}

export default ContactPage;

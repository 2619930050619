import React from 'react';
import {StyleSheet, css} from 'aphrodite';
import Media from 'react-media';

import common from '../../common.css.js';
import executiveSearch from '../../images/executiveSearch.jpg';
function ExecutiveSearch(props) {
    const texts = props.isEn ? english : chinese;
    const title = props.isEn ? "Executive Search" : "管理层人员招聘";
    return(
        <div className={css(common.coloredSectionContainer)}>
            <h1 className={css(common.title)}>{title}</h1>
            <div className={css(styles.contentContainer)}>
                <Media query={{ minWidth: 600 }}>
                    {matches => matches && 
                    <img 
                        src={executiveSearch} 
                        alt="universities image" 
                        className={css(styles.image)}
                    />}
                </Media>
                <div className={css(common.verticalCenterFlexContainer)}>
                    <ul>
                        {texts.map(text=>{
                            return <li className={css(styles.text)}>{text}</li>
                        })}
                    </ul>
                </div>
                <Media query={{ maxWidth: 599 }}>
                    {matches => matches && 
                    <img 
                        src={executiveSearch} 
                        alt="universities image" 
                        className={css(styles.image)}
                    />}
                </Media>
            </div>
        </div>
    );
}

export default ExecutiveSearch;

const styles = StyleSheet.create({
    image: {
        // width: "45%",
        // height: "auto",
        width: "45%",
        height: "100%",
        marginRight: "5%",
        '@media (max-width: 599px)': {
            margin: 0,
            width: "100%",
        }
    },
    text: {
        marginBottom: 22
    },
    contentContainer: {
        display: "flex",
        justifyContent: "space-between",
        '@media (max-width: 599px)': {
            flexDirection: "column"
        }
    },
})

const english=[
    `Experienced and professional executive search team in U.S. and China`,
    `Partner with key accounts, deeply understand and recognize our clients’ business, leadership team and culture`,
    `Focus on active/passive top-performer who makes positive impact in their organization`,
    `Offer executive coaching/consulting to candidates placed in leadership positions`,
    `Cross-culture coaching`
];

const chinese=[
    `在中美均有实体办公室和经验丰富的专业猎头团队`,
    `与领域关键企业建立长期合作关系，深刻理解并认可客户的业务、领导团队和文化`,
    `专注于对团队产生积极影响和显著绩效的主动/被动候选人`,
    `为担任管理层职务的候选人提供指导和咨询`,
    `跨国文化培训`
]
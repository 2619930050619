import React from 'react';
import {StyleSheet, css} from 'aphrodite';
import Button from '@material-ui/core/Button';
import Media from 'react-media';

import common, {primaryColor} from '../../common.css.js';
import training from '../../images/training.jpg';
function Training(props) {
    const title = props.isEn ? "IT Training" : "科技人才培训";
    const texts = props.isEn ? english : chinese;
    return(
        <div className={css(common.coloredSectionContainer)}>
            <h1 className={css(common.title)}>{title}</h1>
            <div className={css(styles.contentContainer)}>
                <Media query={{ minWidth: 600 }}>
                    {matches => matches && 
                    <img 
                        src={training} 
                        alt="training img" 
                        className={css(styles.image)}
                    />}
                </Media>
                <div className={css(common.verticalCenterFlexContainer)}>
                    <ul className={css(styles.textContainer)}>
                        {texts.map(text=>{
                            return <li className={css(styles.text)}>{text}</li>
                        })}
                    </ul>
                    <Media query={{ minWidth: 600 }}>
                        {matches => matches && 
                        <div className={css(common.centerFlexContainer)}>
                            <Button 
                                variant="contained"
                                color={primaryColor}
                                className={css(common.button)}
                            >
                                {props.isEn ? "Learn More" : "培训项目介绍"}
                            </Button>
                        </div>}
                    </Media>
                </div>
                <Media query={{ maxWidth: 599 }}>
                    {matches => matches && 
                    <>
                        <img 
                            src={training} 
                            alt="universities image" 
                            className={css(styles.image)}
                        />
                        <div className={css(common.centerFlexContainer)}>
                            <Button 
                                variant="contained"
                                color={primaryColor}
                                className={css(common.button)}
                            >
                                {props.isEn ? "Learn More" : "培训项目介绍"}
                            </Button>
                        </div>
                    </>}

                </Media>
            </div>
        </div>
    );
}

export default Training;

const styles = StyleSheet.create({
    image: {
        // width: "45%",
        // height: "auto",
        width: "45%",
        height: "100%",
        marginRight: "5%",
        '@media (max-width: 599px)': {
            margin: 0,
            width: "100%",
            marginBottom: 36
        }
    },text: {
        marginBottom: 22
    },
    contentContainer: {
        display: "flex",
        justifyContent: "space-between",
        '@media (max-width: 599px)': {
            flexDirection: "column"
        }
    },
    textContainer: {
        marginBottom: 36,
        '@media (max-width: 599px)': {
            marginBottom: 12
        }
    }
})

const english=[
    `Provide well-trained talents for High Tech companies in US to support their fast growing businesses.`,
    `Provide customized training and coaching programs for young generation to develop their career in High Tech industry`
];

const chinese = [
    `为美国高科技公司提供训练有素的科技人才，以支持其快速发展的公司业务`,
    `为年轻一代提供定制的高科技技术及能力培训计划，助力他们在高科技行业的职业发展`
]
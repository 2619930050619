import React, {useState, useEffect} from 'react';
import {StyleSheet, css} from 'aphrodite';
import Button from '@material-ui/core/Button';

import common from '../../common.css.js';
import {primaryColor} from '../../common.css.js';
import corporates from '../../images/corporates.svg';
import universities from '../../images/universities.svg';
function PartnerIntro(props) {
    const [cHeight, setCHeight] = useState(props.toCHeight);
    const [uHeight, setUHeight] = useState(props.toUHeight);
    useEffect(()=>{
        if(props.toCHeight != cHeight) {
            setCHeight(props.toCHeight);
        }
        if(props.toUHeight != uHeight) {
            setUHeight(props.toUHeight);
        }
    }, [props.toCHeight, props.toUHeight]);
    const titles = props.isEn ? englishTitles : chineseTitles;
    const textsC = props.isEn ? englishC : chineseC;
    const textsU = props.isEn ? englishU : chineseU;
    const title = props.isEn ? "Partner With Us" : "与我们合作";
    function card(title, logo, text1, text2, isEn, isC) {
        return(
            <div className={css(styles.card)}>
                <h3 className={css(styles.cardTitle)}>{title}</h3>
                <img src={logo} alt="logo"/>
                <p className={css(styles.textTop)}>{text1}</p>
                <p className={css(styles.textBottom)}>{text2}</p>
                <Button 
                    variant="contained"
                    className={css(common.button, styles.button)}
                    onClick={isC ? ()=>handleScroll(cHeight) :
                                   ()=>handleScroll(uHeight)}
                >
                    {isEn ? "Learn More" : "了解更多"}
                </Button>
            </div>
        )
    }

    function handleScroll(height) {
        console.log(height);
        window.scrollTo(0, height);
    }
    return(
        <div 
            className={css(common.coloredSectionContainer)}
        >
            <h1 className={css(common.title)}>{title}</h1>
            <div className={css(styles.cardsContainer)}>
                {card(titles[0], images[0], textsC[0], textsC[1], props.isEn, true)}
                {card(titles[1], images[1], textsU[0], textsU[1], props.isEn, false)}
            </div>
        </div>
    );
}

export default PartnerIntro;

const styles = StyleSheet.create({
    textTop: {
        marginTop: 24,
        '@media (max-width: 599px)': {
            padding: 12,
            marginBottom: 0
        }
    },
    textBottom: {
        marginBottom: 60,
        '@media (max-width: 599px)': {
            padding: 12,
            marginTop: 0,
        }
    },
    button: {
        position: "absolute",
        bottom: 24
    },
    card: {
        width: "35%",
        backgroundColor: "white",
        boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.25)",
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // justifyContent: "center",
        padding: "24px 5%",
        position: "relative",
        '@media (max-width: 599px)': {
            width: "90%",
            marginTop: 24,
            marginBottom: 24
        }
    },
    cardTitle: {
        fontSize: 24,
        fontWeight: "bold",
        color: primaryColor
    },
    cardsContainer: {
        display: "flex",
        justifyContent: "space-between",
        '@media (max-width: 599px)': {
            flexDirection: "column"
        }
    }
})

const englishTitles=["Corporates", "Universities"];
const chineseTitles=["企业", "高校"];
const images=[corporates, universities];

const englishC=[
    `Through more than 20 years of accumulated experience, we have exceptional talent for businesses of all sizes.`,
    `We maintain long-term relationships with our clients and stand by our guarantees to deliver a solid process and effective results.`,
];

const englishU=[
    `We value and support career development of the young generation. `,
    `We have solid experience through years’ Global MBA leadership programs with multi-national companies in North America, China, Europe, and Australia to offer internships and full-time opportunities to aspiring young professionals.`
];

const chineseC=[
    `通过20多年的经验积累，我们为各种规模的企业提供杰出人才。`,
    `我们与客户保持长期友好的合作关系，并坚持提供可靠的流程和快速有效的招聘保证。`,
];

const chineseU=[
    `我们重视并支持年轻一代的职业发展。`,
    `通过多年来与北美、中国、欧洲和澳大利亚跨国公司和知名高校联合举办数次全球MBA领导力项目，我们拥有丰富的组织策划经验，为有抱负的年轻专业人士提供实习和全职机会。 `,
]
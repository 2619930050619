import React from 'react';
import {StyleSheet, css} from 'aphrodite';
import Button from '@material-ui/core/Button';

import common from '../../common.css.js';
import {primaryColor} from '../../common.css.js';
function OurAdvantages(props) {
    let title = props.isEn ? "Our Advantages" : "我们的优势";
    let texts = props.isEn ? english : chinese;
    return(
        <div className={css(common.coloredSectionContainer)}>
        <h1 className={css(common.title)}>{title}</h1>
            <div className={css(common.centerFlexContainer)}>
                <ul>
                    {texts.map(text=>{
                    return <li className={css(styles.text)}>{text}</li>
                    })}
                </ul>
            </div>
        </div>
    );
}

export default OurAdvantages;

const styles = StyleSheet.create({
    text: {
        marginBottom: 22
    }
})

const english=[
    `Years of experience focused in the industries we serve`,
    `Strong candidates’ database accumulation`,
    `Value integrity, honesty, and confidentiality in our business practices`,
    `Provide ongoing executive coaching services to leadership placements`,
    `Demonstrated successful track record in bilingual placements`
]

const chinese=[
    `专注于服务领域的多年从业经验`,
    `强大的候选人资料数据库积累`,
    `在业务实践中信仰并贯彻诚信、真实和保密`,
    `为领导层提供持续的领导力和策略培训服务`,
    `招聘全球双语人才的显著成功业绩记录`
]
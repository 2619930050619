import React from 'react';
import {StyleSheet, css} from 'aphrodite';

import common from '../../common.css.js';
import recruiting from '../../images/recruiting.jpg';
function ExecutiveSearch(props) {
    const texts = props.isEn ? english : chinese;
    const title = props.isEn ? "Global and Local Recruiting" : "全球与当地人才招聘";
    return(
        <div className={css(common.sectionContainer)}>
            <h1 className={css(common.title)}>{title}</h1>
            <div className={css(styles.contentContainer)}>
                <ul>
                    {texts.map(text=>{
                        return <li className={css(styles.text)}>{text}</li>
                    })}
                </ul>
                <img 
                    src={recruiting} 
                    alt="universities image" 
                    className={css(styles.image)}
                />
            </div>
        </div>
    );
}

export default ExecutiveSearch;

const styles = StyleSheet.create({
    image: {
        // width: "45%",
        // height: "auto",
        width: "45%",
        height: "100%",
        marginLeft: "5%",
        '@media (max-width: 599px)': {
            margin: 0,
            width: "100%"
        }
    },
    text: {
        marginBottom: 22
    },
    contentContainer: {
        display: "flex",
        justifyContent: "space-between",
        '@media (max-width: 599px)': {
            flexDirection: "column"
        }
    },
})

const english=[
    `Specialize in Healthcare, Education, and IT industries global & local talent sourcing in North America, Asia, Europe, and Australia market.`,
    `Strong database for bilingual professional candidates with global exposure`,
    ` Processes local and global expertise, networks, and resources necessary to support dynamic growth and enables business expansions of your winning team`
];

const chinese=[
    `专注于医疗、教育和IT行业，在北美，亚洲，欧洲和澳大利亚市场提供国际和本土人才招聘`,
    `针对双语候选人的强大数据库、提供全球职位匹配服务`,
    `支持企业增长所需丰富的关系网络和专业知识为企业提供支持，以筛选、识别、和推荐专业的精英候选人，并实现团队的业务扩展`
];
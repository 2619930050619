import React, {useState, useEffect} from 'react';
import {StyleSheet, css} from 'aphrodite';
import Button from '@material-ui/core/Button';
import Media from 'react-media';

import common, {primaryColor} from '../../common.css.js';
import universities from '../../images/universities.jpg';
import stepIcon from '../../images/stepperIcon.svg';
function OurAdvantages(props) {
    const [height, setHeight] = useState(props.scrollHeight);
    useEffect(()=>{
        if(props.scrollHeight != height) {
            setHeight(props.scrollHeight);
        }
    }, [props.scrollHeight]);

    function handleScroll() {
        console.log(height);
        window.scrollTo(0, height);
    }

    const title = props.isEn ? "For Universities" : "与高校合作";
    const subTitle= props.isEn ? "What we offer to your students" : "我们可以提供给您的学生："
    const texts = props.isEn ? english : chinese;
    const titles = props.isEn ? englishTitles : chineseTitles;
    return(
        <div className={css(common.coloredSectionContainer)}>
            <h1 className={css(common.title)}>{title}</h1>
            <p className={css(styles.subTitle)}>{subTitle}</p>
            <div className={css(styles.contentContainer)}>
                <Media query={{ minWidth: 600 }}>
                    {matches => matches && 
                    <img 
                        src={universities} 
                        alt="universities image" 
                        className={css(styles.image)}
                    />}
                </Media>
                <div>
                    <div className={css(styles.stepContainer)}>
                        {texts.map((text, index)=>{
                            let style = index != 3 ? css(styles.stepText) : css(styles.stepTextLast);
                            return(
                                <div>
                                    <img src={stepIcon} alt="step icon" className={css(styles.stepIcon)}/>
                                    <p className={style}>
                                        <span className={css(styles.title)}>{titles[index]}</span>
                                        {text}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <Media query={{ maxWidth: 599 }}>
                    {matches => matches && 
                    <img 
                        src={universities} 
                        alt="universities image" 
                        className={css(styles.image)}
                    />}
                </Media>
            </div>
            <div className={css(common.centerFlexContainer)}>
                <Button 
                    variant="contained"
                    className={css(common.button, styles.button)}
                    onClick={handleScroll}
                >
                    {props.isEn ? "Partner With Us" : "与我们合作"}
                </Button>
            </div>
        </div>
    );
}

export default OurAdvantages;

const styles = StyleSheet.create({
    image: {
        // width: "45%",
        // height: "auto",
        width: "45%",
        height: "100%",
        marginRight: "5%",
        '@media (max-width: 599px)': {
            width: "100%",
            margin: 0
        }
    },
    button: {
        marginTop: 48
    },
    contentContainer: {
        display: "flex",
        justifyContent: "space-between",
        '@media (max-width: 599px)': {
            flexDirection: "column"
        }
    },
    title: {
        fontWeight: 'bold'
    },
    stepContainer: {
        position: "relative"
    },
    stepText: {
        borderLeft: `2px solid ${primaryColor}`,
        paddingLeft: 24,
        paddingBottom: 48,
        margin: 0
    },
    stepTextLast: {
        paddingLeft: 24,
        paddingBottom: 24,
        margin: 0
    },
    stepIcon: {
        marginLeft: -8,
        position: "absolute",
    },
    subTitle: {
        fontWeight: "bold",
        fontSize: 20,
        marginLeft: -6,
        marginBottom: 48
    }
})

const englishTitles=[
    "Work Experience: ", 
    "Cross-Border Study and Corporate Tour: ", 
    "Mentorship: ",
    "Networking Opportunities: "
];

const chineseTitles=[
    "工作经验: ", 
    "跨境企业游学: ", 
    "领域精英导师1对1: ",
    "商业社交活动: "
];

const english=[
    `Technical, business, and medical internships and full-time opportunities that fit their long-term career goals`,
    `10 days to 2 weeks international tours with the mission of self-exploration and improvement`,
    `students will be paired with an assigned manager and mentor that will guide their way`,
    `we offer professional and social events. Your students will have massive opportunities to meet with other interns, entry-level professionals, and team members`
];

const chinese=[
    `适合其长期职业目标的技术和医疗类实习以及全职机会`,
    `以自我探索和提高为目目标、为期10天至2周的出国游学项目`,
    `学生将与指定的管理者和导师配对，提供1对1的行业指导即咨询`,
    `您的学生将有大量机会与其他实习生，入门级专业人士和其他高级团队成员见面交流`
]
import React from 'react';
import {StyleSheet, css} from 'aphrodite';
import Button from '@material-ui/core/Button';
import downIcon from '../../images/down.svg';

import defaultAvatar from '../../images/defaultAvatar.svg';
import common from '../../common.css.js';

function ShortOpenings() {
    function renderOpeningCards() {
        const cards = [
            [defaultAvatar, "company", "position"],
            [defaultAvatar, "company", "position"],
            [defaultAvatar, "company", "position"],
            [defaultAvatar, "company", "position"],
            [defaultAvatar, "company", "position"],
            [defaultAvatar, "company", "position"],
        ]
        return cards.map(card=>{
            return(
                <div className={css(styles.cardContainer)}>
                    <div className={css(styles.imageContainer)}>
                        <img 
                            className={css(styles.image)}
                            src={card[0]} 
                            alt='avatar'
                        />
                    </div>
                    <div className={css(styles.textContainer)}>
                        <p className={css(styles.company)}>{card[1]}</p>
                        <p className={css(styles.position)}>{card[2]}</p>
                    </div>
                </div>
            );
        })
    }
    return(
        <div className={css(common.coloredSectionContainer)}>
            <h1 className={css(common.title)}>Short Openings</h1>
            <div className={css(styles.contentContainer)}>
                {renderOpeningCards()}
            </div>
            <div className={css(common.centerFlexContainer)}>
                <img 
                    className={css(styles.expandIcon)}
                    src={downIcon} 
                    alt="expand arrow"
                />
            </div>
        </div>
    );
}

export default ShortOpenings;

const styles = StyleSheet.create({
    title: {
        // borderBottom: "2px solid blue",
        ":after": {
            content: '""', /* This is necessary for the pseudo element to work. */ 
            display: "block", /* This will put the pseudo element on its own line. */
            margin: "0 auto", /* This will center the border. */
            width: "10%", /* Change this to whatever width you want. */
            paddingTop: 20, /* This creates some space between the element and the border. */
            borderBottom: "3px solid #317EC4" /* This creates the border. Replace black with whatever color you want. */
        },
    },
    cardContainer: {
        display: "flex",
        // justifyContent: "space-around",
        // alignItems: "center",
        width: "30%",
        height: "15vw",
        backgroundColor: "white",
        marginBottom: 48,
        boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.25)",
        borderRadius: 10
    },
    company: {
        fontSize: 16,
        textAlign: "left",
        margin: 0
    },
    contentContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        marginTop: 48
    },
    image: {
        height: "50%",
        width: "auto",
        marginLeft: 12,
        marginRight: 12
    },
    imageContainer: {
        display: "flex",
        alignItems: "center"
    },
    position: {
        fontSize: 12,
        margin: 0,
        textAlign: "left",
    },
    textContainer: {
        marginTop: "15%"
    },
    expandIcon: {
        width: 60,
        height: "auto",
        cursor: "pointer",
        textAlign: "center"
    }
})
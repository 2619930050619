import React from 'react';
import {StyleSheet, css} from 'aphrodite';

import globe from '../../images/globe.svg';
import locate from '../../images/locate.svg';
import mba from '../../images/mba.svg';
import train from '../../images/train.svg';
import common, {primaryColor} from '../../common.css.js';

function renderCards(isEn) {
    const texts = isEn ? english : chinese;
    return texts.map((text, index)=>{
        return <div className={css(styles.card)}>
            <p className={css(styles.cardText)}>{text}</p>
            <img className={css(styles.cardImage)} src={images[index]} alt={text} />
        </div>
    })
}
function OurServices(props) {
    const title = props.isEn ? "Our Services" : "我们的服务";
    return(
        <div className={css(common.coloredSectionContainer)}>
            <h1 className={css(common.title)}>{title}</h1>
            <div className={css(styles.contentContainer)}>
                {renderCards(props.isEn)}
            </div>
        </div>
    );
}

export default OurServices;

const styles = StyleSheet.create({
    card: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: "#FFFFFF",
        boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.25)",
        borderRadius: 15,
        width: "18.5%",
        padding: "1.5%",
        '@media (max-width: 599px)': {
            minWidth: "42%",
            marginBottom: 24
        }
    },
    cardText: {
        fontSize: 21,
        fontWeight: "600",
        color: primaryColor,
        textAlign: "center",
        '@media (max-width: 599px)': {
            fontSize: 14,
        }
    },
    cardImage: {
        width: "70%",
        height: "auto",
    },
    contentContainer: {
        display: "flex",
        justifyContent: "space-between",
        '@media (max-width: 599px)': {
            flexWrap: "wrap"
        }
    },
})

const english=[
    "GLOBAL AND LOCAL HIRING",
    "EXECUTIVE SEARCH",
    "GLOBAL MBA PROGRAMS",
    "IT TRAINING"
];

const chinese=[
    "全球与当地人才招聘",
    "管理层人员招聘",
    "国际MBA项目",
    "科技人才培训"
];

const images=[globe, locate, mba, train]
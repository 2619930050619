import React, {useState} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import {Provider} from 'react-redux';
import {createStore} from 'redux';
import {css, StyleSheet} from 'aphrodite';
import Media from 'react-media';

import rootReducer from './reducers';
import './App.css';
import HomePage from './pages/HomePage/HomePage';
import AboutUsPage from './pages/AboutPage/AboutUs'
import PartnerPage from './pages/PartnerPage/PartnerPage';
import ServicePage from './pages/ServicePage/ServicePage';
import JoinPage from './pages/JoinPage/JoinPage';
import ContactPage from './pages/ContactPage/ContactPage';
import Header from './components/Header'
import SocialBar from './components/SocialBar';
function App() {
  const store = createStore(rootReducer);
  const [isEn, setIsEn] = useState(true);
  return (
    <Provider store={store}>
      <div className={css(styles.negativeContainer)}>
        <Router>
          <Switch>
            <Route exact path="/">
            <HomePage isEn={isEn} setIsEn={()=>setIsEn(!isEn)}/>
            </Route>
            <Route path="/about">
              <Media query={{ minWidth: 600 }}>
                {matches => matches && <SocialBar/>}
              </Media>
              <AboutUsPage isEn={isEn} setIsEn={()=>setIsEn(!isEn)}/>
            </Route>
            <Route path="/partner">
              <Media query={{ minWidth: 600 }}>
                {matches => matches && <SocialBar/>}
              </Media>
              <PartnerPage isEn={isEn} setIsEn={()=>setIsEn(!isEn)}/>
            </Route>
            <Route path="/service">
              <Media query={{ minWidth: 600 }}>
                {matches => matches && <SocialBar/>}
              </Media>
              <ServicePage isEn={isEn} setIsEn={()=>setIsEn(!isEn)}/>
            </Route>
            <Route path="/join">
              <Media query={{ minWidth: 600 }}>
                {matches => matches && <SocialBar/>}
              </Media>
              <JoinPage isEn={isEn} setIsEn={()=>setIsEn(!isEn)}/>
            </Route>
            <Route path="/contact">
              <Media query={{ minWidth: 600 }}>
                {matches => matches && <SocialBar/>}
              </Media>
              <ContactPage isEn={isEn} setIsEn={()=>setIsEn(!isEn)}/>
            </Route>
          </Switch>
        </Router>
      </div>
    </Provider>
  );
}

const styles=StyleSheet.create({
  negativeContainer: {
    position: "absolute",
    top: -144,
    '@media (max-width: 599px)': {
      top: -36
    }
  },
})
export default App;
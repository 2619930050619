import React from 'react';
import {StyleSheet, css} from 'aphrodite';

import globe from '../../images/globe.svg';
import locate from '../../images/locate.svg';
import mba from '../../images/mba.svg';
import train from '../../images/train.svg';

import common from '../../common.css.js';
function AboutUs(props) {
    function renderIconTextBox() {
        const chinese = [
            [globe, "全球与当地人才招聘"],
            [locate, "管理层人员招聘"],
            [mba, "国际MBA项目"],
            [train, "高科技人才培训"]
        ];

        const english = [
            [globe, "Global and Local Hiring"],
            [locate, "Executive Search"],
            [mba, "Global MBA Programs"],
            [train, "IT Training"]
        ];

        const boxes = props.isEn ? english : chinese;
        return boxes.map((box, i)=>(
                <div className={css(styles.iconTextContainer)} key={i}>
                    <img 
                        className={css(styles.image)}
                        src={box[0]} 
                        alt='image'
                    />
                    <p className={css(styles.text)}>{box[1]}</p>
                </div>
            )
        )
    }
    return(
        <div className={css(common.coloredSectionContainer)}>
            <h1 className={css(common.title)}>{props.isEn ? "Our Focus" : "专业领域"}</h1>
            <div className={css(styles.contentContainer)}>
                {renderIconTextBox()}
            </div>
        </div>
    );
}

export default AboutUs;

const styles = StyleSheet.create({
    title: {
        // borderBottom: "2px solid blue",
        ":after": {
            content: '""', /* This is necessary for the pseudo element to work. */ 
            display: "block", /* This will put the pseudo element on its own line. */
            margin: "0 auto", /* This will center the border. */
            width: "10%", /* Change this to whatever width you want. */
            paddingTop: 20, /* This creates some space between the element and the border. */
            borderBottom: "3px solid #317EC4" /* This creates the border. Replace black with whatever color you want. */
        },
    },
    contentContainer: {
        display: "flex",
        justifyContent: "space-between",
        '@media (max-width: 599px)': {
            flexWrap: "wrap"
        }
    },
    iconTextContainer: {
        width: "20%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        '@media (max-width: 599px)': {
            width: "50%",
            marginBottom: 48
        }
    },
    text: {
        textAlign: "center",
        '@media (max-width: 599px)': {
            fontSize: 12
        }
    },
    image: {
        width: "50%",
        height: "auto"
    },
    button: {
        float: "left"
    },
    sectionContainer: {
        backgroundColor: "#F0F8FF",
        // paddingTop: 48,
        // paddingBottom: 48,
        padding: "48px 10%"
    }
})
import React, {useState, useEffect} from 'react';
import {StyleSheet, css} from 'aphrodite';

import trainingBackground from '../../images/trainingBackground.jpg';
import staffingBackground from '../../images/staffingBackground.jpg';
import mbaBackground from '../../images/mbaBackground.jpg';
import Carousel from '../../components/Carousel';

function LandingPage(props) {
    return (
        <Carousel isEn={props.isEn}/>
    );
}

const englishTitles=[
    `Global and Local Staffing`,
    `IT Traning`,
    `Global MBA Program`
];

const chineseTitles=[
    `全球与当地人才招聘`,
    `高科技人才培训`,
    `国际MBA项目`
];

const englishDescs = [
    "With International vision, we find the best talents for your winning team.",
    "Break into IT industry, Everyone Can!",
    "We Prepare Your Next Leadership Team."
];

const chineseDescs=[
    `凭借卓越的国际视野，我们为企业和团队找到优秀的合适人选。`,
    `开启科技专才之路原来如此简单!`,
    `我们帮助您组建未来的商业高级管理人才团队。`
];

const images = [
    staffingBackground,
    trainingBackground,
    mbaBackground
];

const styles = StyleSheet.create({
    negativeContainer: {
        // position: "relative",
        // top: -120
    },
    background: {
        // height: "100%",
        // minHeight: "100%",
        // minWidth: "100%",
        maxHeight: "100%",
        // maxWidth: "100%",
        zIndex: -1,
        bottom: 0,
        right: 0
    },
    fullPageContainer: {
        // height: "calc(100vh + 240px)",
        width: "100vw",
        position: "relative",
        // backgroundImage: `url(${hrBackground})`,
        // // backgroundSize: "auto 100%",
        // backgroundPosition: "right",
        // backgroundSize: "cover",
        padding: "0px 10%",
        // background: "rgba(0, 0, 0, 0.3)"
    },
    fullPageContainer1: {
        // height: "100vh",
        height: "calc(100vh + 120px)",
        // position: "absolute",
        // zIndex: 1,
        // top: 0,
        backgroundImage: `url(${staffingBackground})`,
        backgroundSize: "auto 100%",
        backgroundPosition: "right",
        backgroundSize: "cover",
        padding: "0px 10%",
    },
    fullPageContainer2: {
        // height: "100vh",
        height: "calc(100vh + 120px)",
        // position: "absolute",
        // zIndex: 1,
        // top: 0,
        backgroundImage: `url(${trainingBackground})`,
        backgroundSize: "auto 100%",
        backgroundPosition: "right",
        backgroundSize: "cover",
        padding: "0px 10%",
    },
    fullPageContainer3: {
        // height: "100vh",
        height: "calc(100vh + 120px)",
        // position: "absolute",
        // zIndex: 1,
        // top: 0,
        backgroundImage: `url(${mbaBackground})`,
        backgroundSize: "auto 100%",
        backgroundPosition: "right",
        backgroundSize: "cover",
        padding: "0px 10%",
    },
    title: {
        fontSize: "48px",
        fontWeight: "bold",
        margin: 0,
        color: "white",
        textAlign: "left",
        paddingTop: "50vh",
        ":after": {
            content: '""', /* This is necessary for the pseudo element to work. */ 
            display: "block", /* This will put the pseudo el∂ement on its own line. */
            // margin: "0 auto", /* This will center the border. */
            width: "20%", /* Change this to whatever width you want. */
            paddingTop: 10, /* This creates some space between the element and the border. */
            borderBottom: "6px solid #317EC4" /* This creates the border. Replace black with whatever color you want. */
        },
    },
    desc: {
        color: "white",
        fontSize: "21px",
        width: "60%",
        padding: "5% 0px"
    },
    button: {
        padding: "8px 24px"
    }
})

export default LandingPage;

import React, {useState} from 'react';
import {StyleSheet, css} from 'aphrodite';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import {useDropzone} from 'react-dropzone';
 
import {becomeTalent} from '../../util/email';
import common, {primaryColor} from '../../common.css.js';

function JobSearch(props) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [email, setEmail] = useState("");
    const [industry, setIndustry] = useState("");
    const [year, setYear] = useState("");
    const [location, setLocation] = useState("");
    const [resume, setResume] = useState(null);
    const [linkedin, setLinkedin] = useState("");
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [showFiles, setShowFiles] = useState(true);
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

    let files = acceptedFiles.map(file => (
        <li key={file.path} style={{color:primaryColor}}>
          {file.path} - {file.size} bytes
        </li>
      ));

    function handleSubmit() {
        if(isValidInput()) {
            becomeTalent(firstName, lastName, email, 
                         industry,year, location, 
                         acceptedFiles[0], linkedin);
            setSuccess(true);
            setFirstName("");
            setlastName("");
            setEmail("");
            setIndustry("");
            setYear("");
            setLocation("");
            setLinkedin("");
            // setShowFiles(false);
        } else {
            setShowError(true);
        }
    }
    
    function isValidEmail() {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    function isValidInput() {
        if (firstName == "" || lastName == "" || email == "" || 
            industry == "" || year == "" || location == "") {
            setError("Required fields cannot be empty.");
            return false;
        } else if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            setError("Please enter a valid email address.");
            return false;
        } else {
            setError("");
            setShowError(false);
            return true;
        }
    }

    return(
        <div className={css(common.sectionContainer)}>
            <h1 className={css(common.title)}>{props.isEn ? "Submit Your Resume" : "提交您的简历"}</h1>
            <form className={css(styles.formContainer)} noValidate autoComplete="off">
                <div className={css(styles.firstLineContainer)}>
                    <TextField
                        className={css(styles.shortForm)}
                        id="standard-basic" 
                        label={props.isEn?"First Name":"名"}
                        required 
                        variant="outlined"
                        value={firstName}
                        onChange={(e)=>setFirstName(e.target.value)}
                        // error
                        error={showError && firstName === ""}
                        helperText={showError && firstName === "" ? error : null}
                    />
                    <TextField
                        required 
                        className={css(styles.shortForm)}
                        id="standard-basic" 
                        label={props.isEn?"Last Name":"姓"}
                        variant="outlined"
                        value={lastName}
                        onChange={(e)=>setlastName(e.target.value)}
                        error={showError && lastName === ""}
                        helperText={showError && lastName === "" ? error : null}
                    />
                    <TextField
                        required 
                        className={css(styles.shortForm)}
                        id="standard-basic" 
                        label={props.isEn?"Email":"邮件"}
                        variant="outlined"
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                        error={showError && !isValidEmail()}
                        helperText={showError && !isValidEmail() ? "Please Enter a Valid Email" : null}
                    />
                </div>
                <div className={css(styles.firstLineContainer)}>
                    <TextField
                        required 
                        className={css(styles.shortForm)}
                        id="standard-basic" 
                        label={props.isEn?"Industry":"工作领域"}
                        variant="outlined"
                        value={industry}
                        onChange={(e)=>setIndustry(e.target.value)}
                        error={showError && industry === ""}
                        helperText={showError && industry === "" ? error : null}
                    />
                    <TextField
                        required 
                        className={css(styles.shortForm)}
                        id="standard-basic" 
                        label={props.isEn?"Year of Experience":"专业工作经验年数"}
                        variant="outlined"
                        value={year}
                        onChange={(e)=>setYear(e.target.value)}
                        error={showError && year === ""}
                        helperText={showError && year === "" ? error : null}
                    />
                    <TextField
                        required 
                        className={css(styles.shortForm)}
                        id="standard-basic"  
                        label={props.isEn?"Current Location":"当前所在城市"}
                        variant="outlined"
                        value={location}
                        onChange={(e)=>setLocation(e.target.value)}
                        error={showError && location === ""}
                        helperText={showError && location === "" ? error : null}
                    />
                </div>
                <div>
                    <p>{props.isEn ? `If you want to attach your resume, click "Attach Resume"` : `如果您想附加简历，请单击“上传简历”`}</p>
                    <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()} />
                        <Button 
                            className={css(styles.button)}
                            variant="outlined"
                        >
                            {props.isEn ? "ATTACH RESUME" : "上传简历"}
                        </Button>
                    </div>
                    {showFiles && <ul>{files}</ul>}
                </div>
                <TextField
                    className={css(styles.shortForm)}
                    style={{marginTop: 24}}
                    id="standard-basic"
                    label={props.isEn?"Linkedin Profile":"领英URL"}
                    variant="outlined"
                    value={linkedin}
                    onChange={(e)=>setLinkedin(e.target.value)}
                />
            </form>
            {success && 
            <div className={css(styles.reminder)}>
                <p>
                {props.isEn ? "Your message is sent successfully." : 
                "您的留言已成功发送"}
                </p>
                <Button 
                    className={css(styles.reminderButton)}
                    onClick={()=>setSuccess(false)}
                >
                    <CloseIcon/>
                </Button>
            </div>}
            <div className={css(common.centerFlexContainer)}>
                <Button 
                    className={css(common.button)} 
                    variant="contained"
                    onClick={handleSubmit}
                >
                    {props.isEn ? "Submit" : "提交"}
                </Button>
            </div>
        </div>
    );
}

export default JobSearch;

const styles = StyleSheet.create({
    formContainer: {
        
    },
    firstLineContainer: {
        display: "flex",
        justifyContent: 'space-between',
        marginBottom: 48,
        '@media (max-width: 599px)': {
            flexDirection: "column",
            marginBottom: 0,
            // marginTop: 36
        }
    },
    shortForm: {
        width: "30%",
        '@media (max-width: 599px)': {
            width: "100%",
            marginBottom: 48
        }
    },
    longForm: {
        width: "100%",
        marginBottom: 48
    },
    description: {
        width: "70%",
        textAlign: "left",
        marginTop: 48,
        marginBottom: 48
    },
    button: {
        border: `solid 1px ${primaryColor}`,
        // marginTop: 12,
        // marginBottom: 24,
        color: primaryColor,
        padding: "12px 24px",
        '@media (max-width: 599px)': {
            marginBottom: 0
        }
    },
    reminder: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: primaryColor,
        color: "white",
        height: 60,
        width: 360,
        marginTop: 36,
        marginBottom: 36,
        position: "relative"
    },
    reminderButton: {
        position:"absolute",
        top: 0,
        right: -6,
        color: "white"
    }
})

const texts=[
    `We help candidates with middle to senior levels who are looking for new opportunities or are planning to explore local or oversea professional experience to land in their jobs.`,
    `Submit your resume or LinkedIn URL, our consultant will contact you very soon.`
]
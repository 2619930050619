import { toggleLanguage } from "./actions";

function togleLanguage(state = "EN", action) {
    switch(action.type) {
        case "EN":
            return action.XX
        case "CH":
            return action.YY
        default:
            return state;
    }
}

export default toggleLanguage;
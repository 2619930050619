import React from 'react';
import {StyleSheet, css} from 'aphrodite';
import Button from '@material-ui/core/Button';
import {Link} from "react-router-dom";

import common, {primaryColor} from '../../common.css.js';
// import partnerWithUs from '../../images/partnerWithUs.png';
import partnerWithUs from '../../images/partnerWithUs.jpg';
function OurAdvantages(props) {
    const title = props.isEn ? "Partner With Us" : "与我们合作";
    const text = props.isEn ? english : chinese;
    return(
        <div className={css(styles.background)}>
            <h1 className={css(common.title)}>{title}</h1>
            <div className={css(common.centerFlexContainer)}>
                <p className={css(styles.text)}>{text}</p>
            </div>
            <div className={css(common.centerFlexContainer)}>
                <Link
                    className={css(common.link)}
                    to="/partner"
                >
                    <Button className={css(styles.button)} variant="outlined">
                        {props.isEn ? "PARTNER WITH US" : "与我们合作"}
                    </Button>
                </Link>
            </div>
        </div>
    );
}

export default OurAdvantages;

const styles = StyleSheet.create({
    text:{
        textAlign: "center",
        marginTop: 48,
        maxWidth: "70%"
    },
    button: {
        // backgroundColor: primaryColor,
        border: `solid 1px ${primaryColor}`,
        marginTop: 48,
        marginBottom: 48,
        color: primaryColor,
        fontWeight: "bold"
    },
    background: {
        backgroundImage: `url(${partnerWithUs})`,
        backgroundSize: "100%",
        backgroundPosition: "right",
        backgroundSize: "cover",
        backgroundColor: "#e6e6e6",
        backgroundBlendMode: "overlay",
    }
})

const english=`Partner with us. Whether your business is a start-up, mid-size or Fortune 500, we help you capture the competitive advantage of human resource.`;
const chinese=`无论您是初创企业、中型企业还是世界500强企业，我们都能帮助您精准快速的定位企业人力资源的竞争优势。`;
import React, {useState} from 'react';
import {StyleSheet, css} from 'aphrodite';
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal';

import wechatIcon from '../images/wechat.svg';
import linkedinIcon from '../images/linkedin.svg';
import emailIconColored from '../images/email.svg';
import qr from '../images/qr.png';
import common from '../common.css';

function SocialBar() {
    const[modalOpen, setModalOpen] = useState(false);
    return(
        <>
            <Modal
                open={modalOpen}
                onClose={()=>setModalOpen(false)}
            >
                <div className={css(styles.modal)}>
                    <img src={qr} alt="qr code" className={css(styles.qr)}/>
                </div>
            </Modal>
            <div className={css(styles.barContainer)}>
                <a
                    onClick={()=>setModalOpen(true)}
                >
                    <img 
                        src={wechatIcon} 
                        alt="wechat icon" 
                        className={css(styles.icon)}
                    />
                </a>
                <a 
                    href="https://www.linkedin.com/company/internationalrecruiting/"
                    target="_blank"
                >
                    <img 
                        src={linkedinIcon} 
                        alt="linkedin icon" 
                        className={css(styles.icon)}
                    />
                </a>
                <a 
                    href="mailto:elena@internationalrecruit.net"
                    target="_blank"
                >
                    <img 
                        src={emailIconColored} 
                        alt="email icon" 
                        className={css(styles.icon)}
                    />
                </a>
            </div>
        </>
    );
}

const styles = StyleSheet.create({
    barContainer: {
        display: "flex",
        flexDirection:"column",
        backgroundColor: "white",
        borderRadius: "24px",
        height: "120px",
        width: "48px",
        position: "sticky",
        left: "100%",
        top: "40%",
        boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.25)",
        paddingTop: 6,
        paddingBottom: 6,
        zIndex: 100
    },
    barParent: {
        // position: "sticky",
        // top: "40%",
        // left: 0,
        // // height: "100%"
    },
    icon: {
        marginTop: 3,
        cursor: "pointer",
        marginLeft: 8,
        width: "70%",
    },
    qr: {
        height: "120px",
        width: "120px",
        // top: "40%"
    },
    modal: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        position: "absolute",
        top: "40%",
        left: "45%"
    }
})

export default SocialBar;
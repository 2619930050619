import React from 'react';
import Carousel from 'react-material-ui-carousel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {css, StyleSheet} from 'aphrodite';

import common from '../common.css.js';
import trainingBackground from '../images/trainingBackground.jpg';
import trainingMobile from '../images/trainingMobile.jpg'
import staffingBackground from '../images/staffingBackground.jpg';
import mbaBackground from '../images/mbaBackground.jpg';

function Example(props) {
    const titles = props.isEn ? englishTitles : chineseTitles;
    const descs = props.isEn ? englishDescs : chineseDescs;
    const containers=[
        styles.fullPageContainer1,
        styles.fullPageContainer2,
        styles.fullPageContainer3
    ]

    function scrollDown() {
        window.scroll(0, window.innerHeight+24);
    }

    return (
        <Carousel
            autoplay
            animation="slide"
            indicators={false}
        >
            {[0, 1, 2].map(i => {
                return (
                    <div className={css(containers[i])}>
                        <h1 className={css(styles.title)}>{titles[i]}</h1>
                        <p className={css(styles.desc)}>{descs[i]}</p>
                        <Button variant="contained" className={css(common.button, styles.button)} onClick={scrollDown}>
                           {props.isEn ? "Learn More" : "了解更多"}
                        </Button>
                    </div>
                );
            })}
        </Carousel>
    );
}

export default Example;

const englishTitles=[
    `Global and Local Staffing`,
    `IT Training`,
    `Global MBA Program`
];

const chineseTitles=[
    `全球与当地人才招聘`,
    `高科技人才培训`,
    `国际MBA项目`
];

const englishDescs = [
    "With International vision, we find the best talents for your winning team.",
    "Break into IT industry, Everyone Can!",
    "We Prepare Your Next Leadership Team."
];

const chineseDescs=[
    `凭借卓越的国际视野，我们为企业和团队找到优秀的合适人选。`,
    `开启科技专才之路原来如此简单!`,
    `我们帮助您组建未来的商业高级管理人才团队。`
];

const images = [
    staffingBackground,
    trainingBackground,
    mbaBackground
];


const styles= StyleSheet.create({
    fullPageContainer1: {
        // backgroundColor: "red",
        maxWidth: "100vw",
        minHeight: "calc(100vh + 120px)",
        position:"relative",
        backgroundImage: `url(${staffingBackground})`,
        backgroundSize: "auto 100%",
        backgroundPosition: "right",
        backgroundSize: "cover",
        backgroundColor: "#424242",
        backgroundBlendMode: "overlay",
        padding: "0px 10%",
        '@media (max-width: 599px)': {
            minHeight: "calc(50vh + 120px)",
            // minHeight: "40vh",
            // maxHeight: "40vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            backgroundPosition: "50% 75%;",
            // backgroundSize: "contain",
            // backgroundRepeat: "no-repeat"
        },
        // img {
        //     -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
        //     filter: grayscale(100%);
        //   }
    },
    fullPageContainer2: {
        // backgroundColor: "red",
        paddingTop: 200,
        maxWidth: "100vw",
        minHeight: "calc(100vh + 120px)",
        position:"relative",
        backgroundColor: "#424242",
        backgroundBlendMode: "overlay",
        padding: "0px 10%",
        '@media (min-width: 600px)': {
            backgroundImage: `url(${trainingBackground})`,
            backgroundPosition: "right",
            backgroundSize: "cover",
        },
        '@media (max-width: 599px)': {
            minHeight: "calc(50vh + 120px)",
            // minHeight: "40vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            backgroundImage: `url(${trainingMobile})`,
            backgroundPosition: "bottom",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat"
        },
    },
    fullPageContainer3: {
        // backgroundColor: "red",
        maxWidth: "100vw",
        minHeight: "calc(100vh + 120px)",
        position:"relative",
        backgroundImage: `url(${mbaBackground})`,
        backgroundSize: "auto 100%",
        backgroundPosition: "right",
        backgroundSize: "cover",
        backgroundColor: "#424242",
        backgroundBlendMode: "overlay",
        padding: "0px 10%",
        '@media (max-width: 599px)': {
            minHeight: "calc(50vh + 120px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            backgroundPosition: "25% 75%;",
        },
    },
    title: {
        fontSize: "72px",
        fontWeight: "bold",
        margin: 0,
        color: "white",
        textAlign: "left",
        paddingTop: "45vh",
        // borderBottom: "6px solid #317EC4",
        ":after": {
            content: '""', /* This is necessary for the pseudo element to work. */ 
            display: "block", /* This will put the pseudo el∂ement on its own line. */
            // margin: "0 auto", /* This will center the border. */
            width: "20%", /* Change this to whatever width you want. */
            paddingTop: 10, /* This creates some space between the element and the border. */
            borderBottom: "6px solid #317EC4" /* This creates the border. Replace black with whatever color you want. */
        },
        '@media (max-width: 599px)': {
            fontSize: 36,
            paddingTop: "240px"
        },
    },
    desc: {
        color: "white",
        fontSize: "24px",
        width: "60%",
        padding: "5% 0px",
        paddingTop: 0,
        '@media (max-width: 599px)': {
            fontSize: "14px",
        }
    },
    button: {
        padding: "8px 24px",
        '@media (max-width: 599px)': {
            marginBottom: 48
        }
    },
    image: {
        // width: "100vw",
        // height: "100vh",
        position: "absolute",
        bottom: 0,
        right: 0,
    }
});
import emailjs from 'emailjs-com';
import axios from 'axios';
import FormData, {getHeaders} from 'form-data';

const EMAIL_JS_SERVICE_ID="irc_smtp_server";
const EMAIL_JS_CONTACT_TEMPLATE_ID="contact_us";
const EMAIL_JS_TALENT_TEMPLATE_ID="talent";
const EMAIL_JS_USER_ID="user_p8kxPNTNe6yCwFEESCF4h";
const AWS_LAMBDA_API_ENDPOINT=`https://2xyokln113.execute-api.us-west-2.amazonaws.com/default/irc-resume`

// const USER_ID = process.env.REACT_APP_EMAIL_JS_USER_ID;
// const SERVICE_ID = process.env.REACT_APP_EMAIL_JS_SERVICE_ID;
// const CONTACT_TEMPLATE_ID = process.env.REACT_APP_EMAIL_JS_CONTACT_TEMPLATE_ID;
// const TALENT_TEMPLATE_ID = process.env.REACT_APP_EMAIL_JS_TALENT_TEMPLATE_ID;
// const LAMBDA_API_ENDPOINT = process.env.REACT_APP_LAMBDA_API_ENDPOINT;
const USER_ID = EMAIL_JS_USER_ID;
const SERVICE_ID = EMAIL_JS_SERVICE_ID;
const CONTACT_TEMPLATE_ID = EMAIL_JS_CONTACT_TEMPLATE_ID;
const TALENT_TEMPLATE_ID = EMAIL_JS_TALENT_TEMPLATE_ID;
const LAMBDA_API_ENDPOINT = AWS_LAMBDA_API_ENDPOINT;

export function contactUs(firstName, lastName, email, content) {
    const params={
        "first_name": firstName,
        "last_name": lastName,
        "email": email,
        "content": content
    };
    console.log(USER_ID, SERVICE_ID, CONTACT_TEMPLATE_ID, params);
    emailjs
    .send(SERVICE_ID, CONTACT_TEMPLATE_ID, params, USER_ID)
    .then(res=>{
        console.log("successfully sent email", res);
    })
    .catch(err=>{
        console.log("error sending email", err);
    });
}

export function becomeTalent(firstName, lastName, email, 
                             industry, year, location,
                             resume, linkedin="" ) {
    const emailParams = {
        "first_name": firstName,
        "last_name": lastName,
        "email": email,
        "industry": industry,
        "year": year,
        "location:": location,
        "linkedin": linkedin
    };
    const lambdaParams= {
        method: "GET",
        url: "https://cors-anywhere.herokuapp.com/" + LAMBDA_API_ENDPOINT + `?name=${resume.name}`,
        // url: LAMBDA_API_ENDPOINT + `?name=${resume.name}`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            "X-Requested-With": "*"
        },
        crossdomain: true
    };
    axios(lambdaParams)
    .then(res=>{
        uploadFileToS3(res.data, resume)
        .then(res=>{
            console.log("hi ", res);
            emailjs
            .send(SERVICE_ID, TALENT_TEMPLATE_ID, emailParams, USER_ID)
            .then(res=>{
                console.log("successfully sent email", res);
            })
            .catch(err=>{
                console.log("error sending email", err);
            });
        })
        .catch(err=>{
            console.log("err", err);
        })
    })
    .catch(err=>{
        console.error(err);
    })
}


/**
 * Upload file to S3 with previously received pre-signed POST data.
 * @param presignedPostData
 * @param file
 * @returns {Promise<any>}
 */
const uploadFileToS3 = (presignedPostData, file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key]);
      });
  
      // Actual file has to be appended last.
      formData.append("file", file);
  
      const xhr = new XMLHttpRequest();
      xhr.open("POST", presignedPostData.url, true);
      xhr.send(formData);
      xhr.onload = function() {
        this.status === 204 ? resolve() : reject(this.responseText);
      };
    });
  };
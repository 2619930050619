import React from 'react';
import {StyleSheet, css} from 'aphrodite';
import Button from '@material-ui/core/Button';
import Media from 'react-media';

import common, {primaryColor} from '../../common.css.js';
import ourHistory from '../../images/ourHistory.jpg';
import dot from '../../images/ourHistory/dot.svg';
import shanghai from '../../images/ourHistory/shanghai.svg';
import healthcare from '../../images/ourHistory/healthcare.svg';
import ctrip from '../../images/ourHistory/ctrip.svg';
import seattle from '../../images/ourHistory/seattle.svg';
import rrd1 from '../../images/ourHistory/rrd1.svg';
import license from '../../images/ourHistory/license.svg';
import rrd2 from '../../images/ourHistory/rrd2.svg';
import train from '../../images/ourHistory/train.svg';
import calgary from '../../images/ourHistory/calgary.svg';
import recruiters from '../../images/ourHistory/recruiters.svg';

function topCard(image, time, description) {
    return (
        <div className={css(styles.card)}>
            <img className={css(styles.logo)}src={image} alt="image" />
            <div className={css(styles.textContainer)}>
                <p className={css(styles.time)}>{time}</p>
                <p className={css(styles.description)}>{description}</p>
            </div>
            <img className={css(styles.topDot)}src={dot} alt="dot" />
        </div>
    );
}

function bottomCard(image, time, description) {
    return (
        <div className={css(styles.card)}>
            <img className={css(styles.bottomDot)}src={dot} alt="image" />
            <div className={css(styles.bottomTextContainer)}>
                <p className={css(styles.time)}>{time}</p>
                <p className={css(styles.description)}>{description}</p>
            </div>
            <img className={css(styles.logo)}src={image} alt="image" />
        </div>
    );
}

function leftCard(image, time, description) {
    return (
        <div className={css(styles.card)}>
            <img className={css(styles.leftCardLogo)}src={image} alt="image" />
            <div className={css(styles.line)}></div>
            <img className={css(styles.leftDot)}src={dot} alt="dot" />
            <div className={css(styles.textContainer)}>
                <p className={css(styles.time)}>{time}</p>
                <p className={css(styles.description)}>{description}</p>
            </div>
        </div>
    );
}

function rightCard(image, time, description) {
    return (
        <div className={css(styles.rightCard)}>
            <img className={css(styles.rightCardLogo)}src={image} alt="image" />
            <div className={css(styles.rightLine)}></div>
            <img className={css(styles.rightDot)}src={dot} alt="dot" />
            <div className={css(styles.textContainer)}>
                <p className={css(styles.timeRight)}>{time}</p>
                <p className={css(styles.description)}>{description}</p>
            </div>
        </div>
    );
}

function OurHistory(props) {
    const topCards = props.isEn ? topCardsEnglish : topCardsChinese;
    const bottomCards = props.isEn ? bottomCardsEnglish : bottomCardsChinese;
    
    function renderDesktop() {
        return(
            <div className={css(styles.background)}>
                <h1 className={css(common.title)}>{props.isEn ? "Our History" : "企业历程"}</h1>
                <div className={css(styles.topCardsContainer)}>
                    {topCards.map(card=>(
                        topCard(card[0], card[1], card[2])
                    ))}
                </div>
                <div className={css(styles.bottomCardsContainer)}>
                    {bottomCards.map(card=>(
                        bottomCard(card[0], card[1], card[2])
                    ))}
                </div>
            </div>
        );
    }

    function renderMobile() {
        return(
            <div className={css(styles.background)}>
                <h1 className={css(common.title)}>{props.isEn ? "Our History" : "企业历程"}</h1>
                <div className={css(styles.mobileContainer)}>
                    <div className={css(styles.leftCardsContainer)}>
                        {topCards.map(card=>(
                            leftCard(card[0], card[1], card[2])
                        ))}
                    </div>
                    <div className={css(styles.rightCardsContainer)}>
                        {bottomCards.map(card=>(
                            rightCard(card[0], card[1], card[2])
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    return(
        <Media query={{ minWidth: 600 }}>
                {matches => matches ? 
                renderDesktop() :
                renderMobile()}
        </Media>
    )
    
}

export default OurHistory;

const styles = StyleSheet.create({
    background: {
        backgroundImage: `url(${ourHistory})`,
        backgroundSize: "100%",
        backgroundPosition: "right",
        backgroundSize: "cover",
    },
    card: {
        width: "20%",
        height: "100%",
        position: "relative",
        '@media (max-width: 599px)': {
            width: "100%"
        }
    },
    rightCard: {
        width: "100%",
        // height: "calc(20% - 60px)",
        position: "relative",
        // borderTop: 60,
        // marginBottom: 0
    },
    time: {
        fontSize: 16,
        color: primaryColor,
        margin: 0,
        paddingTop: 24,
        paddingLeft: 12,
        paddingBottom: 12
    },
    timeRight: {
        fontSize: 16,
        color: primaryColor,
        margin: 0,
        paddingTop: 24,
        paddingLeft: 12,
        paddingBottom: 12,
        textAlign: "right"
    },
    description: {
        fontSize: 14,
        margin: 0,
        marginLeft: 12,
        minHeight: "16vh"
    },
    textContainer: {
        '@media (min-width: 600px)': {
            borderLeft: `solid 1px ${primaryColor}`,
        },
        marginBottom: -12
    },
    bottomTextContainer: {
        borderLeft: `solid 1px ${primaryColor}`,
        marginTop: -10
    },
    line: {
        width: 36,
        borderBottom: `1px solid ${primaryColor}`,
        position: "absolute",
        top: 24, 
        right: 12
    },
    rightLine: {
        width: 36,
        borderBottom: `1px solid ${primaryColor}`,
        position: "absolute",
        top: 24, 
        left: 12
    },
    logo: {
        width: "50%",
        height: "auto",
        marginLeft: "-25%",
        marginBottom: -4,
        // '@media (max-width: 599px)': {
        //     position:
        //     margin: 0
        // }
    },
    leftCardLogo: {
        width: "25%",
        position: "absolute",
        top: 0,
        right: 48,
        // left: "50%"
    },
    rightCardLogo: {
        width: "25%",
        position: "absolute",
        top: 0,
        left: 48,
    },
    topDot: {
        marginLeft: -3,
    },
    bottomDot: {
        // marginBottom: -4,
        // marginTop: -4,
        marginLeft: -3,
    },
    leftDot: {
        position: "absolute",
        top: 21,
        right: 12
    },
    rightDot: {
        position: "absolute",
        top: 21,
        left: 12
    },
    topCardsContainer: {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: `solid 2px ${primaryColor}`,
        marginLeft: "10%",
        marginRight: "15%",
        paddingBottom: 5,
        // paddingRight: "20%",
    },
    bottomCardsContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginLeft: "15%",
        marginRight: "10%",
        paddingBottom: 60,
        height: "40%",
    },
    leftCardsContainer: {
        height: "20%",
        marginLeft: 24,
    },
    rightCardsContainer: {
        height: "20%",
        borderLeft: `solid 1px ${primaryColor}`,
        marginRight: 24,
        paddingTop: 60
    },
    mobileContainer: {
        display: "flex",
    }
})

const topCardsEnglish=[
    [shanghai, "2014 Apr","Set up Shanghai office and focused on IT"],
    [ctrip, "2014 Nov", "Organized Ctrip International brands promotions in U.S."],
    [rrd1, "2016 Apr", "Exclusive hiring for RRD Asia 1st Leadership Association Program"],
    [rrd2, "2017 Apr", "Exclusive hiring for RRD Asia 2nd Leadership Association Program"],
    [calgary, "2021", "Calgary Canada office established"]
]

const bottomCardsEnglish=[
    [healthcare, "2014 Jul", "Developed healthcare industry local and international hiring"],
    [seattle, "2015 Aug", "Established Seattle office and focused on IT"],
    [license, "2016 Sept", "License registration to do business in California"],
    [train, "2019", "Launch High Tech training programs in Seattle"],
    [recruiters, "2022", "Expanded recruiting team to Chicago"]
]

const topCardsChinese=[
    [shanghai, "2014年4月","设立中国上海总部，专注于IT领域"],
    [ctrip, "2014年11月", "在美国举办携程国际品牌宣传活动"],
    [rrd1, "2016年4月", "负责当纳利亚洲第一届领导力项目的独家策划和招聘"],
    [rrd2, "2017年4月", "负责当纳利亚洲第二届领导力项目的独家策划和招聘"],
    [calgary, "2021", "加拿大卡尔加里办公室成立"]
]

const bottomCardsChinese=[
    [healthcare, "2014年7月", "发展医疗行业的国际和当地人力招聘服务"],
    [seattle, "2015年8月", "在美国华盛顿州西雅图设立美国办公室，专注于IT领域"],
    [license, "2016年9月", "注册并获得在加利福尼亚州经商的许可证"],
    [train, "2019年", "在西雅图启动高科技人才培训计划"],
    [recruiters, "2022", "招聘团队扩展至芝加哥"]
]
import React, {useState, useEffect} from 'react';
import {css, StyleSheet} from 'aphrodite';
import Media from 'react-media';

import LandingPage from './LandingPage';
import AboutUs from './AboutUs';
import OurFocus from './OurFocus';
import Testimonials from './Testimonials';
import ShortOpening from './ShortOpenings';
import ContactUs from '../../components/ContactForm';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SocialBar from '../../components/SocialBar';

function HomePage(props) {
    const[whiteHeader, setWhiteHeader] = useState(false);
    // useEffect(()=>{
    //     window.scrollTo(0, 0);
    // });
    useEffect(()=>{
        window.addEventListener('scroll', handleScroll)
    });
    const handleScroll=()=>{
        if(window.scrollY >= window.innerHeight && !whiteHeader) {
            setWhiteHeader(true);
        } else if(window.scrollY < window.innerHeight && whiteHeader) {
            setWhiteHeader(false);
        }
    }
    return(
        <div className={css(styles.negativeContainer)}>
            <Header isEn={props.isEn} setIsEn={props.setIsEn} isWhite={whiteHeader}/>
            {/* <SocialBar isEn={props.isEn}/> */}
            <Media query={{ minWidth: 600 }}>
                {matches => matches && <SocialBar/>}
            </Media>
            <LandingPage isEn={props.isEn}/>
            <AboutUs isEn={props.isEn}/>
            <OurFocus isEn={props.isEn}/>
            <Testimonials isEn={props.isEn}/>
            {/* <ShortOpening isEn={props.isEn}/> */}
            {/* <ContactUs isEn={props.isEn} title={props.isEn ? "Contact Us" : "联系我们"} isWhite={false}/> */}
            <Footer isEn={props.isEn}/>
        </div>
    );
}
const styles=StyleSheet.create({
    negativeContainer: {
        width: "100vw",
        position: "absolute",
        top: -108,
        zIndex: 100,
        // '@media (max-width: 599px)': {
        //     top: 0
        // }
    },
})
export default HomePage;
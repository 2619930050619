import React, {useState} from 'react';
import {StyleSheet, css} from 'aphrodite';
import MapIcon from '@material-ui/icons/MapOutlined';
import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import Modal from '@material-ui/core/Modal';

import downIcon from '../images/down.svg';
import logo from '../images/logo.png';
import wechatIcon from '../images/wechat.svg';
import linkedinIcon from '../images/linkedin.svg';
import emailIconColored from '../images/email.svg';
import common, {lightTextColor} from '../common.css.js';
import qr from '../images/qr.png';

function Footer(props) {
    const[modalOpen, setModalOpen] = useState(false);
    return(
        <div className={css(styles.footerContainer)}>
            <img src={logo} alt="company logo" className={css(styles.logo)}/>
            <div className={css(styles.contentContainer)}>
                <div className={css(styles.smallColumnContainer)}>
                    <p>{props.isEn ? "About Us" : "关于我们"}</p>
                    <p>{props.isEn ? "Blogs & News" : "新闻资讯"}</p>
                    <p>{props.isEn ? "Career" : "加入我们"}</p>
                </div>
                <div className={css(styles.largeColumnContainer)}>
                    <h4>{props.isEn ? "Seattle Office" : "美国西雅图办公室"}</h4>
                    <div className={css(common.flexContainer)}>
                        <MapIcon className={css(styles.icon)}/>
                        <p>500 108th Ave NE Suite 1100, Bellevue, WA 98004</p>
                    </div>
                    <div className={css(common.flexContainer)}>
                        <PhoneIcon className={css(styles.icon)}/>
                        <p>1 (206) 850-4883</p>
                    </div>
                    <div className={css(common.flexContainer)}>
                        <EmailIcon className={css(styles.icon)}/>
                        <p>elena@internationalrecruit.net</p>
                    </div>
                </div>
                <div className={css(styles.largeColumnContainer)}>
                    <h4>{props.isEn ? "Shanghai Office" : "中国上海办公室"}</h4>
                    <div className={css(common.flexContainer)}>
                        <MapIcon className={css(styles.icon)}/>
                        <p>1405, Tongsheng Building, No.458 Fushan Road, Shanghai 200120</p>
                    </div>
                    <div className={css(common.flexContainer)}>
                        <PhoneIcon className={css(styles.icon)}/>
                        <p>86 2161093169</p>
                    </div>
                    <div className={css(common.flexContainer)}>
                        <EmailIcon className={css(styles.icon)}/>
                        <p>admin@yuanzhihr.com</p>
                    </div>
                </div>
                <div className={css(styles.largeColumnContainer)}>
                    <h4>{props.isEn ? "Calgary Office" : "加拿大卡尔加里办公室"}</h4>
                    <div className={css(common.flexContainer)}>
                        <MapIcon className={css(styles.icon)}/>
                        <p>Unit 1208, 734 7 Ave SW, Calgary, AB T2P 3P8</p>
                    </div>
                    <div className={css(common.flexContainer)}>
                        <PhoneIcon className={css(styles.icon)}/>
                        <p>1 (403) 383-0811</p>
                    </div>
                    <div className={css(common.flexContainer)}>
                        <EmailIcon className={css(styles.icon)}/>
                        <p>zijiaohan@internationalrecruit.net</p>
                    </div>
                </div>
                <div className={css(styles.smallColumnContainer)}>
                    <p>{props.isEn ? "Stay Connected" : "联系我们"}</p>
                    <div className={css(styles.iconContainer)}>
                        <a>
                            <img 
                                src={wechatIcon} 
                                alt="wechat icon" 
                                className={css(styles.icon)}
                                onClick={()=>setModalOpen(true)}
                            />
                        </a>
                        <a 
                            href="https://www.linkedin.com/company/internationalrecruiting/"
                            target="_blank"
                        >
                            <img src={linkedinIcon} alt="linkedin icon" className={css(styles.icon)}/>
                        </a>
                        <a 
                            href="mailto:elena@internationalrecruit.net"
                            target="_blank"
                        >
                            <img src={emailIconColored} alt="wechat icon" className={css(styles.icon)}/>
                        </a>
                    </div>
                </div>
            </div>
            <Modal
                open={modalOpen}
                onClose={()=>setModalOpen(false)}
            >
                <div className={css(styles.modal)}>
                    <img src={qr} alt="qr code" className={css(styles.qr)}/>
                </div>
            </Modal>
        </div>
    );
}

export default Footer;

const styles = StyleSheet.create({
    footerContainer: {
        padding: "24px 10%",
        backgroundColor: "#666666",
        // backgroundColor: "#343434",
    },
    contentContainer: {
        display: "flex",
        justifyContent: "space-between",
        color: lightTextColor,
        '@media (max-width: 599px)': {
            flexDirection: "column"
        }
    },
    iconContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "75%",
        '@media (max-width: 599px)': {
            width: "100%"
        }
    },
    smallColumnContainer: {
        width: "15%",
        '@media (max-width: 599px)': {
            width: "100%"
        }
    },
    largeColumnContainer: {
        width: "30%",
        '@media (max-width: 599px)': {
            width: "100%"
        }
    },
    logo: {
        marginLeft: -6
    },
    icon: {
        marginTop: 14,
        marginRight: 6,
        '@media (max-width: 599px)': {
            width: 36
        }
    },
    modal: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        position: "absolute",
        top: "40%",
        left: "45%"
    },
    qr: {
        height: "120px",
        width: "120px",
        // top: "40%"
    },
});